// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface PubReportPrePayPayedReq {
  /** 回報付款帳號 */
  payAccount?: string;
}

/**
 * 付款資訊
 */
export interface MbrStoreOrderPrePayConfigDto {
  /** 銀行 */
  bank?: string;

  /** 銀行帳號 */
  account?: string;

  /**
   * 訂金金額(舊)
   * @format double
   */
  prePayAmount?: number;

  /** 訂金說明 */
  description?: string;
}

export interface PubOrderPrePayDto {
  /**
   * 付款期限
   * @format date-time
   */
  expireAt?: string;

  /** 回報付款帳號 */
  payAccount?: string;

  /**
   * 回報時間
   * @format date-time
   */
  reportAt?: string;

  /**
   * 應付款金額
   * @format double
   */
  amountToPay?: number;

  /** 指定付款方式, null 表示可讓客戶自行選擇 */
  specifyPaymentType?: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";

  /** 客戶使用的付款方式 */
  usedPaymentType?: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";

  /** 付款資訊 */
  config?: MbrStoreOrderPrePayConfigDto;

  /** 可用訂金支付方式 */
  availablePaymentTypes?: ("CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH")[];
}

/**
 * 主圖片
 */
export interface FileDto {
  /**
   * id of File
   * @format int32
   */
  id: number;

  /** 檔案名稱 */
  name: string;

  /** 檔案類型 */
  contentType: string;

  /** 檔案所屬物件類型 */
  relateType?:
    | "GROUP"
    | "STORE"
    | "USER"
    | "ORDER"
    | "RESOURCE"
    | "STORE_REPORT"
    | "CMS_CONSUMER_IMPORT"
    | "SIGNATURE"
    | "ORDER_CHECKOUT"
    | "BILL";

  /**
   * 檔案所屬物件編號
   * @format int32
   */
  relateTypeId?: number;

  /** 檔案URL */
  url?: string;
}

/**
 * Group
 */
export interface PubGroupInStoreDto {
  /** 名稱 */
  name: string;

  /** 網址SubDomain/Path */
  groupDomain: string;

  /** 主圖片 */
  logoPhotoFile?: FileDto;

  /** 主圖片 */
  mainPhotoFile?: FileDto;
}

export interface PubOrderDetailDto {
  /**
   * 建立時間
   * @format date-time
   */
  createdAt: string;

  /** 狀態（staticOptions.orderStatus） */
  status: "DRAFT" | "WAITING" | "CHECKED_IN" | "CANCEL" | "CONSUMER_CANCEL" | "NO_SHOW";

  /** 訂金狀態（staticOptions.prePayStatus） */
  prePayStatus?: "WAITING" | "REPORT_PAYED" | "CHECKED";

  /** 訂單建立方式（staticOptions.orderCreationType） */
  creationType: "PAD" | "MOBILE" | "ONLINE" | "RM" | "TWODAY";

  /**
   * 服務時間(分)
   * @format int32
   */
  totalServiceMinute: number;

  /**
   * 數量
   * @format int32
   */
  amount: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /**
   * 結束時間
   * @format date-time
   */
  endAt: string;

  /** 性別（staticOptions.gender） */
  consumerGender?: "MALE" | "FEMALE" | "OTHER";

  /** 其他需求(顧客) */
  mbrExtraDemand?: string;

  /** Resource */
  resource: PubResourceInOrderDto;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 顧客預約出席確認 */
  checkReserve?: boolean;

  /** 不指定預約內容 */
  unSpecifyResourceGroup?: SimpleUnSpecifyResourceGroupDto;

  /** 顧客姓名 */
  consumerNameInOrder?: string;

  /** Store */
  store: PubStoreInOrderDto;

  /** 服務清單 */
  services: PubServiceInOrderDto[];

  /** 問題回答清單 */
  questionReplies: PubOrderQuestionReplyDto[];

  /** 是否可以取消 */
  cancelableForMbr?: boolean;
}

/**
 * 問題回答清單
 */
export interface PubOrderQuestionReplyDto {
  /** 問題 */
  question: PubResourceQuestionInOrderDto;

  /** 回答 */
  reply: string[];
}

/**
 * Resource
 */
export interface PubResourceInOrderDto {
  /** 設計師名稱 */
  name: string;

  /** 服務數量單位 */
  unit: string;
}

/**
 * 問題
 */
export interface PubResourceQuestionInOrderDto {
  /** 問題 */
  question?: string;
}

/**
 * 服務清單
 */
export interface PubServiceInOrderDto {
  /** 名稱 */
  name?: string;

  /** 服務代號 */
  nameShort?: string;

  /** Sub Service */
  subServices: PubSubServiceInOrderDto[];

  /** Category Name */
  categoryName?: string;
}

/**
 * Store
 */
export interface PubStoreInOrderDto {
  /** 名稱 */
  name: string;

  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** 地址-國家 */
  addressCountry: string;

  /** 地址-城市 */
  addressCity: string;

  /** 地址-地區 */
  addressDistrict: string;

  /** 地址-街道、樓層、號碼 */
  addressDetail: string;

  /** Facebook連結 */
  socialLinkFb?: string;

  /** Instagram連結 */
  socialLinkInstagram?: string;

  /** LINE官方帳號 */
  socialLinkLine?: string;

  /** 電話不顯示 */
  hidePhone: boolean;

  /** 地址不顯示 */
  hideAddress: boolean;

  /** Group */
  group: PubGroupInStoreDto;

  /** 商店註冊來源 */
  completeSource?:
    | "ADVERTISING"
    | "BEAUTY_COMMUNITY"
    | "COMPETITOR_USAGE"
    | "LINE_PLUGIN"
    | "GOOGLE_SEARCH"
    | "FRIEND_REFERRAL";
}

/**
 * Sub Service
 */
export interface PubSubServiceInOrderDto {
  /** 名稱 */
  name: string;

  /**
   * 時間長度（分）
   * @format int32
   */
  timeInMinutes: number;

  /**
   * 價格
   * @format double
   */
  price: number;
}

/**
 * 不指定預約內容
 */
export interface SimpleUnSpecifyResourceGroupDto {
  /**
   * id
   * @format int32
   */
  id: number;
  name: string;
}

export interface PubDocTemplateItemReq {
  /**
   * 問卷題目排序
   * @format int32
   */
  sort: number;
  questionType: "SINGLE" | "MULTIPLE" | "SHORT_ANSWER" | "DESCRIPTION";

  /** 問卷題目是否必填 */
  required: "ON" | "OFF";

  /** 問卷題目文字(題目) = ConsumerDocTemplateItem.content */
  questionText: string;

  /** 問卷題目回答答案 */
  shortAnswer?: string;

  /** 問卷題目文字(全部選項回傳) */
  optionsText?: string[];

  /** 問卷題目選擇選項 */
  options?: number[];
}

export interface PubFillConsumerDocReq {
  /** 文件名稱 */
  docTitle: string;
  itemList: PubDocTemplateItemReq[];

  /** @format int32 */
  signaturePathFileId?: number;

  /** @format int32 */
  signatureImageFileId?: number;
}

export interface PubConsumerDocDto {
  /** @format int32 */
  consumerId: number;

  /** @format int32 */
  docTemplateId: number;

  /** @format int32 */
  fileId?: number;
  fileName?: string;

  /** @format int32 */
  signatureId?: number;
  uuid?: string;

  /** @format int32 */
  storeId?: number;
  filled: boolean;
}

export interface CommitFileUploadReq {
  /**
   * id of File
   * @format int32
   */
  id: number;

  /** 檔案所屬物件類型 */
  relateType?:
    | "GROUP"
    | "STORE"
    | "USER"
    | "ORDER"
    | "RESOURCE"
    | "STORE_REPORT"
    | "CMS_CONSUMER_IMPORT"
    | "SIGNATURE"
    | "ORDER_CHECKOUT"
    | "BILL";

  /**
   * 檔案所屬物件編號
   * @format int32
   */
  relateTypeId?: number;
}

/**
 * 信用卡資訊, paymentType=CREDIT_CARD時必填
 */
export interface CreditCardInfoDto {
  /** 信用卡號 */
  cardNumber: string;

  /** 信用卡安全碼 */
  cardSecurityCode: string;

  /**
   * 信用卡到期日-年
   * @format int32
   */
  expiryYear: number;

  /**
   * 信用卡到期日-月
   * @format int32
   */
  expiryMonth: number;
}

export interface MbrOrderPrePayPaymentReq {
  /** 付款類型 */
  paymentType: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";

  /** 付款流程結束後，要導向的前端網址 */
  redirectUrl: string;

  /** 信用卡資訊, paymentType=CREDIT_CARD時必填 */
  cardInfo?: CreditCardInfoDto;
}

export interface MbrOrderPrePayPaymentRes {
  nextAction: "REDIRECT_TO_URL" | "COMPLETE";

  /** nextAction=COMPLETE 時，可直接跳轉回訂單 */
  orderShortId: string;

  /** nextAction=REDIRECT_TO_URL 時，前端要跳轉的網址 */
  nextUrl?: string;
}

export interface LineMarketPlaceBindGroupReq {
  /** Line callback authorization code */
  code: string;

  /** 商家串接通行碼 */
  groupBindCode: string;
}

export type LineMarketPlaceBindGroupRes = object;

export interface PubJoinHungerReq {
  /** Recaptcha token */
  recaptchaToken: string;

  /** 公司/品牌名稱 */
  companyName: string;

  /** 聯絡窗口姓名 */
  contactName: string;

  /** 手機號碼 */
  phone: string;

  /** 電子郵件 */
  email: string;

  /** 可拜訪日期 */
  availableDate: string;

  /** 可拜訪時間 */
  availableTime: string;

  /** 簡述您的需求 */
  description: string;
}

export interface GroupSignupEmailVerificationReq {
  token: string;
  code: string;
}

export interface GroupSignupEmailVerificationRes {
  token: string;
}

export interface GroupSignupIssueEmailVerifyReq {
  token: string;
  email: string;
}

export interface GroupSignupIssueEmailVerifyRes {
  token: string;
}

export interface GroupSignupInitReq {
  inviteCode?: string;
}

export interface GroupSignupInitRes {
  token: string;
}

export interface GroupSignupCompleteReq {
  token: string;
  storeInfo: GroupSignupStoreInfoReq;
  groupInfo: GroupSignupGroupInfoReq;
  groupUserInfo: GroupSignupGroupUserInfoReq;
  invoiceDetail?: GroupSignupInvoiceDetailReq;
  tapPayCard?: GroupSignupTapPayCardReq;
}

export interface GroupSignupGroupInfoReq {
  /** 名稱 */
  name: string;
}

export interface GroupSignupGroupUserInfoReq {
  /** 名稱  */
  name: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** 電話號碼 */
  phone: string;
}

export interface GroupSignupInvoiceDetailReq {
  /** 統一編號 */
  vatNo: string;

  /** 公司抬頭 */
  invoiceHeading: string;

  /** 帳務Email */
  invoiceEmail: string;
}

export interface GroupSignupStoreInfoReq {
  /** 地址-國家 */
  addressCountry: string;

  /** 地址-城市 */
  addressCity: string;

  /** 地址-城市 */
  addressCityLabel: string;

  /** 地址-地區 */
  addressDistrict: string;

  /** 地址-地區 */
  addressDistrictLabel: string;

  /** 地址-街道、樓層、號碼 */
  addressDetail: string;

  /** 統一編號 */
  vatNo: string;

  /** Instagram連結 */
  socialLinkInstagram?: string;

  /** LINE官方帳號 */
  socialLinkLine?: string;

  /** 商店註冊來源 */
  completeSource?:
    | "ADVERTISING"
    | "BEAUTY_COMMUNITY"
    | "COMPETITOR_USAGE"
    | "LINE_PLUGIN"
    | "GOOGLE_SEARCH"
    | "FRIEND_REFERRAL";

  /**
   * Plan Id
   * @format int32
   */
  planId?: number;
}

export interface GroupSignupTapPayCardReq {
  /** TapPay prime */
  prime: string;

  /** 持卡人電話 */
  phoneNumber: string;

  /** 持卡人姓名 */
  name: string;

  /** 持卡人Email */
  email: string;

  /** 持卡人郵遞區號 */
  zipCode?: string;

  /** 持卡人地址 */
  address?: string;

  /** 持卡人身分證號 */
  nationalId?: string;
}

export interface GroupSignupCompleteRes {
  token: string;
}

export interface IssueFileUploadReq {
  /** 檔案名稱 */
  name: string;

  /** 檔案類型 */
  contentType: string;
}

export interface IssueFileUploadResult {
  /**
   * id of File
   * @format int32
   */
  id: number;

  /** 上傳URL */
  uploadUrl: string;
}

export interface ValidateRmAuthReq {
  /** Validate Token */
  validateToken: string;

  /** 驗證碼 */
  code: string;
}

export interface ValidateRmAuthRes {
  /** Token */
  token: string;
}

export interface IssueRmAuthReq {
  /** Email */
  email: string;

  /** Recaptcha token */
  recaptchaToken?: string;
}

export interface IssueRmAuthRes {
  /** Validate Token */
  validateToken: string;
}

export interface PadDeviceAuthReq {
  /** 通行碼 */
  optCode: string;

  /** 裝置名稱 */
  name: string;
}

export interface PadDeviceAuthRes {
  /** Token */
  token: string;
}

export interface PadIssueMgtAuthReq {
  /** Email */
  email: string;
}

export interface PadIssueMgtAuthRes {
  /** Token */
  token: string;
}

export interface PadValidateMgtAuthReq {
  /** 認證碼 */
  code: string;

  /** Token */
  token: string;
}

export interface PadValidateMgtAuthRes {
  /** Token */
  token: string;
}

export interface ValidateMgtAuthReq {
  /** Validate Token */
  validateToken: string;

  /** 驗證碼 */
  code: string;
}

export interface ValidateMgtAuthRes {
  /** Token */
  token: string;
}

export interface IssueMgtAuthReq {
  /** Email */
  email: string;

  /** Recaptcha token */
  recaptchaToken?: string;
}

export interface IssueMgtAuthRes {
  /** Validate Token */
  validateToken: string;
}

export interface ValidateMbrAuthReq {
  /** 電話認證類型 */
  phoneAuthType?: "SMS" | "CALL" | "CALL_OTP";

  /** Validate Token */
  validateToken: string;

  /** 驗證碼(簡訊驗證必填) */
  code?: string;

  /**
   * 延遲ms
   * @format int64
   */
  delayMs?: number;
}

export interface ValidateMbrAuthRes {
  /** Token */
  token: string;

  /** 是否需要填寫註冊資訊 */
  signUpRequired: boolean;
}

export interface MbrLmpAuthReq {
  /** lmp action token */
  lmpToken: string;
}

export interface MbrLineLiffLoginReq {
  /** Group 網址SubDomain/Path */
  groupDomain: string;

  /** 是否為從Twoday登入 */
  twoday?: boolean;

  /** line id */
  lineId: string;

  /** id token */
  idToken: string;
}

export interface IssueMbrAuthReq {
  /** Group 網址SubDomain/Path */
  groupDomain: string;

  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** 是否為從Twoday登入 */
  twoday?: boolean;

  /** Recaptcha token */
  recaptchaToken: string;

  /** 重發簡訊（改用三竹） */
  resend?: boolean;

  /** 電話認證類型 */
  phoneAuthType?: "SMS" | "CALL" | "CALL_OTP";
}

export interface IssueMbrAuthRes {
  /** Validate Token */
  validateToken: string;

  /** 撥號驗證撥打號碼 */
  phoneToCall?: string;
}

export interface MbrFirebaseAuthReq {
  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** 是否為從Twoday登入 */
  twoday?: boolean;

  /** id token */
  idToken: string;
}

export interface ValidateAdmAuthReq {
  /** Validate Token */
  validateToken: string;

  /** 驗證碼 */
  code: string;
}

export interface ValidateAdmAuthRes {
  /** Token */
  token: string;
}

export interface IssueAdmAuthReq {
  /** Email */
  email: string;

  /** Recaptcha token */
  recaptchaToken?: string;
}

export interface IssueAdmAuthRes {
  /** Validate Token */
  validateToken: string;
}

export interface SysTimeDto {
  /** @format date-time */
  time: string;
}

/**
 * 十二星座
 */
export interface OptionDto {
  key: string;
  label: string;
  active: boolean;
}

export interface StaticOptionDto {
  /** 累計計算方式 */
  accumulationType: OptionDto[];

  /** 帳單折扣類型 */
  billDiscountType: OptionDto[];

  /** 帳單明細項目類型 */
  billItemRef: OptionDto[];

  /** 帳單付款類型 */
  billPaymentType: OptionDto[];

  /** 帳單關聯物件類型類型 */
  billRef: OptionDto[];

  /** 帳單狀態 */
  billStatus: OptionDto[];

  /** 帳單類型 */
  billType: OptionDto[];

  /** 店員類型 */
  clerkType: OptionDto[];

  /** 會員年齡層 */
  consumerAgeLevel: OptionDto[];

  /** 文件內容種類 */
  consumerDocTemplateItemType: OptionDto[];

  /** 紅利點數紀錄異動類型 */
  consumerGroupBonusHistoryModifyType: OptionDto[];

  /** 儲值金異動類型 */
  consumerGroupDepositHistoryModifyType: OptionDto[];

  /** Consumer狀態 */
  consumerMembership: OptionDto[];

  /** 顧客訂金設定 */
  consumerOrderPrePayConfig: OptionDto[];

  /** 會員付款狀態 */
  consumerPaymentStatus: OptionDto[];

  /** 票券歷史紀錄類型 */
  consumerVoucherHistoryType: OptionDto[];

  /** Device狀態 */
  deviceStatus: OptionDto[];

  /** 性別 */
  gender: OptionDto[];

  /** 管理員角色 */
  groupUserRole: OptionDto[];

  /** GroupUser狀態 */
  groupUserStatus: OptionDto[];

  /** 商家邀請獎勵狀態 */
  invitedStoreCreditStatus: OptionDto[];

  /** Manager權限 */
  managerPermission: OptionDto[];

  /** 訊息發送時間點 */
  messageDeliveryTimeWindow: OptionDto[];

  /** 商家後台報表類型 */
  mgrGroupStoreSummaryReportType: OptionDto[];

  /** 通知種類 */
  notifyMessageType: OptionDto[];

  /** 開啟關閉狀態 */
  onOffStatus: OptionDto[];

  /** 網路預約取消設定 */
  onlineOrderCancelBlockType: OptionDto[];

  /** 結帳折扣類型 */
  orderCheckoutDiscountType: OptionDto[];

  /** Order聯絡狀態 */
  orderConnectionStatus: OptionDto[];

  /** Order建立方式 */
  orderCreationType: OptionDto[];

  /** Order訂金狀態 */
  orderPrePayStatus: OptionDto[];

  /** Order狀態 */
  orderStatus: OptionDto[];

  /** 電話認證類型 */
  phoneAuthType: OptionDto[];

  /** 降級原因清單列表 */
  planDowngradedReason: OptionDto[];

  /** Plan繳費週期 */
  planPaymentPeriod: OptionDto[];

  /** 訂金設定會員分類 */
  prePayConsumerCondition: OptionDto[];

  /** 訂金收款期間 */
  prePayPaymentPeriod: OptionDto[];

  /** 訂金收款方式 */
  prePayPaymentType: OptionDto[];

  /** 訂金處理方式 */
  prePayProcessType: OptionDto[];

  /** 問題類型 */
  questionType: OptionDto[];

  /** 預約限制種類 */
  reservationLimitType: OptionDto[];

  /** 設計師顏色代碼 */
  resourceColor: OptionDto[];

  /**  事件行事曆建立方式 */
  resourceEventScheduleCreationType: OptionDto[];

  /** Resource網路開放From (最晚預約時間) */
  resourceOnlineReservationFrom: OptionDto[];

  /** Resource網路開放最早時限 (開放區間) */
  resourceOnlineReservationUntil: OptionDto[];

  /** Resource網路開放最晚時間 - 模式 */
  resourceOnlineReservationUntilMode: OptionDto[];

  /** Resource網路開放最晚時限 - 指定日期 */
  resourceOnlineReservationUntilPeroid: OptionDto[];

  /** Resource網路開放設定覆蓋類型 */
  resourceOpeningScheduleOverrideType: OptionDto[];

  /** Resource網路開放設定類型 */
  resourceOpeningScheduleType: OptionDto[];

  /** Resource Question填寫類型 */
  resourceQuestionOptionRequireType: OptionDto[];

  /** Resource Question類型 */
  resourceQuestionOptionType: OptionDto[];

  /** 預約訊息發送狀態 */
  scheduledMessageProcessStatus: OptionDto[];

  /** 預約訊息發送方式 */
  scheduledMessageSendWay: OptionDto[];

  /** 商店註冊來源 */
  storeCompleteSource: OptionDto[];

  /** 商家付款紀錄類型 */
  storePaymentHistoryType: OptionDto[];

  /** 商家設定金流類型 */
  storePaymentIntegrationGateway: OptionDto[];

  /** 商家設定金流類型 */
  storePaymentIntegrationGatewayType: OptionDto[];

  /** Store付款狀態 */
  storePaymentStatus: OptionDto[];

  /** 商家點數異動類型 */
  storePointHistoryType: OptionDto[];

  /** 商品庫存異動紀錄類型 */
  storeProductStockHistoryType: OptionDto[];

  /** Sms扣款狀態 */
  storeSmsBillStatus: OptionDto[];

  /** Store狀態 */
  storeStatus: OptionDto[];

  /** SubService選擇類型 */
  subServiceOptionType: OptionDto[];

  /** Twoday開放日 */
  twodayOpenDay: OptionDto[];

  /** Twoday 商家服務類型 */
  twodayStoreServiceCategory: OptionDto[];

  /** 票券滿額設定 */
  voucherAmountFulfillType: OptionDto[];

  /** 票券設定折抵類型 */
  voucherBillUsageType: OptionDto[];

  /** 票券折抵類型 */
  voucherDiscountType: OptionDto[];

  /** 票券使用期限 */
  voucherExpiration: OptionDto[];

  /** 票券可使用店家類型 */
  voucherTemplateStoreUsableType: OptionDto[];

  /** 票券類型 */
  voucherType: OptionDto[];

  /** 十二星座 */
  zodiacSign: OptionDto[];
}

export interface LineMarketPlaceAuthInfo {
  authUrl: string;
}

export interface PubGroupDto {
  /** 名稱 */
  name: string;

  /** 網址SubDomain/Path */
  groupDomain: string;

  /** 介紹 */
  description: string;

  /**
   * Logo圖片Id
   * @format int32
   */
  logoPhotoFileId?: number;

  /**
   * 主圖片Id
   * @format int32
   */
  mainPhotoFileId?: number;

  /** 主圖片 */
  logoPhotoFile?: FileDto;

  /** 主圖片 */
  mainPhotoFile?: FileDto;
}

export interface PubGroupLineConfigDto {
  liffId?: string;
}

export interface PubConsumerDocTemplateDetailDto {
  /** 品牌名稱 */
  groupName: string;

  /** 文件 */
  consumerDocTemplate: PubConsumerDocTemplateDto;

  /** 文件選項內容 */
  consumerDocTemplateItem: PubConsumerDocTemplateItemDto[];

  /** @format int32 */
  signatureId?: number;
  signaturePublicKey: string;
}

/**
 * 文件
 */
export interface PubConsumerDocTemplateDto {
  /**
   * 品牌id
   * @format int32
   */
  groupId: number;

  /** 文件標題 */
  title: string;

  /** 是否需要簽名 */
  signRequired: "ON" | "OFF";
}

/**
 * 文件選項內容
 */
export interface PubConsumerDocTemplateItemDto {
  /**
   * 排序
   * @format int32
   */
  sort: number;

  /** 內容種類 */
  type: "SINGLE" | "MULTIPLE" | "SHORT_ANSWER" | "DESCRIPTION";

  /** 回答是否必填 */
  required: "ON" | "OFF";

  /** 內容 = DocTemplateItemFilling.questionText */
  content: string;

  /** 選項內容 */
  options: string[];
}

export interface PubConfigDto {
  /** 時間設定 */
  timeConfig: PubTimeConfigDto;
}

/**
 * 時間設定
 */
export interface PubTimeConfigDto {
  /**
   * server時區
   * @format int32
   */
  serverTimeOffset: number;
}

/**
 * API Version
 * @example 20220901
 */
export type ApiVersion = string;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "http://hunger-sit-api.ap-northeast-1.elasticbeanstalk.com",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Hunger API - Public
 * @version 1.0
 * @baseUrl http://hunger-sit-api.ap-northeast-1.elasticbeanstalk.com
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  pubOrderApi = {
    /**
     * No description
     *
     * @tags PubOrderApi
     * @name ReportOrderPrePayPayed
     * @summary reportOrderPrePayPayed 回報訂金付款
     * @request PUT:/api/v3/p/orders/{shortId}/prepay
     */
    reportOrderPrePayPayed: (shortId: string, data: PubReportPrePayPayedReq, params: RequestParams = {}) =>
      this.request<PubOrderPrePayDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/orders/${shortId}/prepay`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PubOrderApi
     * @name ConfirmOrderReserve
     * @summary confirmOrderReserve 顧客確定預約出席
     * @request PUT:/api/v3/p/orders/{orderShortId}/confirmOrderReserve
     */
    confirmOrderReserve: (orderShortId: string, params: RequestParams = {}) =>
      this.request<PubOrderDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/orders/${orderShortId}/confirmOrderReserve`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PubOrderApi
     * @name ConsumerCancelOrder
     * @summary consumerCancelOrder 顧客取消訂單
     * @request PUT:/api/v3/p/orders/{orderShortId}/cancel
     */
    consumerCancelOrder: (orderShortId: string, params: RequestParams = {}) =>
      this.request<PubOrderDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/orders/${orderShortId}/cancel`,
        method: "PUT",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PubOrderApi
     * @name IssueOrderPrePay
     * @summary issueOrderPrePay 開始訂金付款
     * @request POST:/api/v3/p/orders/{shortId}/prePay
     */
    issueOrderPrePay: (shortId: string, data: MbrOrderPrePayPaymentReq, params: RequestParams = {}) =>
      this.request<MbrOrderPrePayPaymentRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/orders/${shortId}/prePay`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PubOrderApi
     * @name GetOrderPrePayInfoByShortId
     * @summary getOrderPrePayInfoByShortId 用ShortId取得訂單訂金資料
     * @request GET:/api/v3/p/orders/{shortId}/prePayInfo
     */
    getOrderPrePayInfoByShortId: (shortId: string, params: RequestParams = {}) =>
      this.request<PubOrderPrePayDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/orders/${shortId}/prePayInfo`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags PubOrderApi
     * @name GetOrderDetail
     * @summary getOrderDetail 取得Order詳細資訊
     * @request GET:/api/v3/p/orders/{orderShortId}
     */
    getOrderDetail: (orderShortId: string, params: RequestParams = {}) =>
      this.request<PubOrderDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/orders/${orderShortId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  mbrAuthApi = {
    /**
     * No description
     *
     * @tags MbrAuthApi
     * @name ValidateAuth2
     * @summary validateAuth 商家前台手機驗證碼登入
     * @request POST:/api/v3/p/auth/mbr/validate
     */
    validateAuth2: (data: ValidateMbrAuthReq, params: RequestParams = {}) =>
      this.request<ValidateMbrAuthRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/auth/mbr/validate`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrAuthApi
     * @name LmpAuth
     * @summary lmpAuth 商家前台LMP連結登入
     * @request POST:/api/v3/p/auth/mbr/lmpAuth
     */
    lmpAuth: (data: MbrLmpAuthReq, params: RequestParams = {}) =>
      this.request<ValidateMbrAuthRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/auth/mbr/lmpAuth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrAuthApi
     * @name LineLiffAuth
     * @summary lineLiffAuth 商家前台Line Liff登入
     * @request POST:/api/v3/p/auth/mbr/lineLiffAuth
     */
    lineLiffAuth: (data: MbrLineLiffLoginReq, params: RequestParams = {}) =>
      this.request<ValidateMbrAuthRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/auth/mbr/lineLiffAuth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrAuthApi
     * @name IssueAuth2
     * @summary issueAuth 商家前台發送手機驗證碼
     * @request POST:/api/v3/p/auth/mbr/issue
     */
    issueAuth2: (data: IssueMbrAuthReq, params: RequestParams = {}) =>
      this.request<IssueMbrAuthRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/auth/mbr/issue`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrAuthApi
     * @name FirebaseAuth
     * @summary firebaseAuth 商家前台Firebase登入
     * @request POST:/api/v3/p/auth/mbr/fireBaseAuth
     */
    firebaseAuth: (data: MbrFirebaseAuthReq, params: RequestParams = {}) =>
      this.request<ValidateMbrAuthRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/auth/mbr/fireBaseAuth`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  staticOptionApi = {
    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetAllStaticOptions
     * @summary Get All Static Options
     * @request GET:/api/v3/p/staticOptions
     */
    getAllStaticOptions: (params: RequestParams = {}) =>
      this.request<StaticOptionDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetZodiacSign
     * @summary Get 十二星座
     * @request GET:/api/v3/p/staticOptions/zodiacSign
     */
    getZodiacSign: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/zodiacSign`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetVoucherType
     * @summary Get 票券類型
     * @request GET:/api/v3/p/staticOptions/voucherType
     */
    getVoucherType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/voucherType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetVoucherTemplateStoreUsableType
     * @summary Get 票券可使用店家類型
     * @request GET:/api/v3/p/staticOptions/voucherTemplateStoreUsableType
     */
    getVoucherTemplateStoreUsableType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/voucherTemplateStoreUsableType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetVoucherExpiration
     * @summary Get 票券使用期限
     * @request GET:/api/v3/p/staticOptions/voucherExpiration
     */
    getVoucherExpiration: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/voucherExpiration`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetVoucherDiscountType
     * @summary Get 票券折抵類型
     * @request GET:/api/v3/p/staticOptions/voucherDiscountType
     */
    getVoucherDiscountType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/voucherDiscountType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetVoucherBillUsageType
     * @summary Get 票券設定折抵類型
     * @request GET:/api/v3/p/staticOptions/voucherBillUsageType
     */
    getVoucherBillUsageType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/voucherBillUsageType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetVoucherAmountFulfillType
     * @summary Get 票券滿額設定
     * @request GET:/api/v3/p/staticOptions/voucherAmountFulfillType
     */
    getVoucherAmountFulfillType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/voucherAmountFulfillType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetTwodayStoreServiceCategory
     * @summary Get Twoday 商家服務類型
     * @request GET:/api/v3/p/staticOptions/twodayStoreServiceCategory
     */
    getTwodayStoreServiceCategory: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/twodayStoreServiceCategory`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetTwodayOpenDay
     * @summary Get Twoday開放日
     * @request GET:/api/v3/p/staticOptions/twodayOpenDay
     */
    getTwodayOpenDay: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/twodayOpenDay`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetSubServiceOptionType
     * @summary Get SubService選擇類型
     * @request GET:/api/v3/p/staticOptions/subServiceOptionType
     */
    getSubServiceOptionType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/subServiceOptionType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStoreStatus
     * @summary Get Store狀態
     * @request GET:/api/v3/p/staticOptions/storeStatus
     */
    getStoreStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storeStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStoreSmsBillStatus
     * @summary Get Sms扣款狀態
     * @request GET:/api/v3/p/staticOptions/storeSmsBillStatus
     */
    getStoreSmsBillStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storeSmsBillStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStoreProductStockHistoryType
     * @summary Get 商品庫存異動紀錄類型
     * @request GET:/api/v3/p/staticOptions/storeProductStockHistoryType
     */
    getStoreProductStockHistoryType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storeProductStockHistoryType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStorePointHistoryType
     * @summary Get 商家點數異動類型
     * @request GET:/api/v3/p/staticOptions/storePointHistoryType
     */
    getStorePointHistoryType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storePointHistoryType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStorePaymentStatus
     * @summary Get Store付款狀態
     * @request GET:/api/v3/p/staticOptions/storePaymentStatus
     */
    getStorePaymentStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storePaymentStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStorePaymentIntegrationGateway
     * @summary Get 商家設定金流類型
     * @request GET:/api/v3/p/staticOptions/storePaymentIntegrationGateway
     */
    getStorePaymentIntegrationGateway: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storePaymentIntegrationGateway`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStorePaymentIntegrationGatewayType
     * @summary Get 商家設定金流類型
     * @request GET:/api/v3/p/staticOptions/storePaymentIntegrationGatewayType
     */
    getStorePaymentIntegrationGatewayType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storePaymentIntegrationGatewayType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStorePaymentHistoryType
     * @summary Get 商家付款紀錄類型
     * @request GET:/api/v3/p/staticOptions/storePaymentHistoryType
     */
    getStorePaymentHistoryType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storePaymentHistoryType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetStoreCompleteSource
     * @summary Get 商店註冊來源
     * @request GET:/api/v3/p/staticOptions/storeCompleteSource
     */
    getStoreCompleteSource: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/storeCompleteSource`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetScheduledMessageSendWay
     * @summary Get 預約訊息發送方式
     * @request GET:/api/v3/p/staticOptions/scheduledMessageSendWay
     */
    getScheduledMessageSendWay: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/scheduledMessageSendWay`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetScheduledMessageProcessStatus
     * @summary Get 預約訊息發送狀態
     * @request GET:/api/v3/p/staticOptions/scheduledMessageProcessStatus
     */
    getScheduledMessageProcessStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/scheduledMessageProcessStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceQuestionOptionType
     * @summary Get Resource Question類型
     * @request GET:/api/v3/p/staticOptions/resourceQuestionOptionType
     */
    getResourceQuestionOptionType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceQuestionOptionType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceQuestionOptionRequireType
     * @summary Get Resource Question填寫類型
     * @request GET:/api/v3/p/staticOptions/resourceQuestionOptionRequireType
     */
    getResourceQuestionOptionRequireType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceQuestionOptionRequireType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceOpeningScheduleType
     * @summary Get Resource網路開放設定類型
     * @request GET:/api/v3/p/staticOptions/resourceOpeningScheduleType
     */
    getResourceOpeningScheduleType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceOpeningScheduleType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceOpeningScheduleOverrideType
     * @summary Get Resource網路開放設定覆蓋類型
     * @request GET:/api/v3/p/staticOptions/resourceOpeningScheduleOverrideType
     */
    getResourceOpeningScheduleOverrideType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceOpeningScheduleOverrideType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceOnlineReservationUntil
     * @summary Get Resource網路開放最早時限 (開放區間)
     * @request GET:/api/v3/p/staticOptions/resourceOnlineReservationUntil
     */
    getResourceOnlineReservationUntil: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceOnlineReservationUntil`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceOnlineReservationUntilPeroid
     * @summary Get Resource網路開放最晚時限 - 指定日期
     * @request GET:/api/v3/p/staticOptions/resourceOnlineReservationUntilPeroid
     */
    getResourceOnlineReservationUntilPeroid: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceOnlineReservationUntilPeroid`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceOnlineReservationUntilMode
     * @summary Get Resource網路開放最晚時間 - 模式
     * @request GET:/api/v3/p/staticOptions/resourceOnlineReservationUntilMode
     */
    getResourceOnlineReservationUntilMode: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceOnlineReservationUntilMode`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceOnlineReservationFrom
     * @summary Get Resource網路開放From (最晚預約時間)
     * @request GET:/api/v3/p/staticOptions/resourceOnlineReservationFrom
     */
    getResourceOnlineReservationFrom: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceOnlineReservationFrom`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceEventScheduleCreationType
     * @summary Get  事件行事曆建立方式
     * @request GET:/api/v3/p/staticOptions/resourceEventScheduleCreationType
     */
    getResourceEventScheduleCreationType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceEventScheduleCreationType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetResourceColor
     * @summary Get 設計師顏色代碼
     * @request GET:/api/v3/p/staticOptions/resourceColor
     */
    getResourceColor: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/resourceColor`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetReservationLimitType
     * @summary Get 預約限制種類
     * @request GET:/api/v3/p/staticOptions/reservationLimitType
     */
    getReservationLimitType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/reservationLimitType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetQuestionType
     * @summary Get 問題類型
     * @request GET:/api/v3/p/staticOptions/questionType
     */
    getQuestionType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/questionType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPrePayProcessType
     * @summary Get 訂金處理方式
     * @request GET:/api/v3/p/staticOptions/prePayProcessType
     */
    getPrePayProcessType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/prePayProcessType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPrePayPaymentType
     * @summary Get 訂金收款方式
     * @request GET:/api/v3/p/staticOptions/prePayPaymentType
     */
    getPrePayPaymentType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/prePayPaymentType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPrePayPaymentPeriod
     * @summary Get 訂金收款期間
     * @request GET:/api/v3/p/staticOptions/prePayPaymentPeriod
     */
    getPrePayPaymentPeriod: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/prePayPaymentPeriod`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPrePayConsumerCondition
     * @summary Get 訂金設定會員分類
     * @request GET:/api/v3/p/staticOptions/prePayConsumerCondition
     */
    getPrePayConsumerCondition: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/prePayConsumerCondition`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPlanPaymentPeriod
     * @summary Get Plan繳費週期
     * @request GET:/api/v3/p/staticOptions/planPaymentPeriod
     */
    getPlanPaymentPeriod: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/planPaymentPeriod`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPlanDowngradedReason
     * @summary Get 降級原因清單列表
     * @request GET:/api/v3/p/staticOptions/planDowngradedReason
     */
    getPlanDowngradedReason: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/planDowngradedReason`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetPhoneAuthType
     * @summary Get 電話認證類型
     * @request GET:/api/v3/p/staticOptions/phoneAuthType
     */
    getPhoneAuthType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/phoneAuthType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOrderStatus
     * @summary Get Order狀態
     * @request GET:/api/v3/p/staticOptions/orderStatus
     */
    getOrderStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/orderStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOrderPrePayStatus
     * @summary Get Order訂金狀態
     * @request GET:/api/v3/p/staticOptions/orderPrePayStatus
     */
    getOrderPrePayStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/orderPrePayStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOrderCreationType
     * @summary Get Order建立方式
     * @request GET:/api/v3/p/staticOptions/orderCreationType
     */
    getOrderCreationType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/orderCreationType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOrderConnectionStatus
     * @summary Get Order聯絡狀態
     * @request GET:/api/v3/p/staticOptions/orderConnectionStatus
     */
    getOrderConnectionStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/orderConnectionStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOrderCheckoutDiscountType
     * @summary Get 結帳折扣類型
     * @request GET:/api/v3/p/staticOptions/orderCheckoutDiscountType
     */
    getOrderCheckoutDiscountType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/orderCheckoutDiscountType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOnlineOrderCancelBlockType
     * @summary Get 網路預約取消設定
     * @request GET:/api/v3/p/staticOptions/onlineOrderCancelBlockType
     */
    getOnlineOrderCancelBlockType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/onlineOrderCancelBlockType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetOnOffStatus
     * @summary Get 開啟關閉狀態
     * @request GET:/api/v3/p/staticOptions/onOffStatus
     */
    getOnOffStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/onOffStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetNotifyMessageType
     * @summary Get 通知種類
     * @request GET:/api/v3/p/staticOptions/notifyMessageType
     */
    getNotifyMessageType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/notifyMessageType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetMgrGroupStoreSummaryReportType
     * @summary Get 商家後台報表類型
     * @request GET:/api/v3/p/staticOptions/mgrGroupStoreSummaryReportType
     */
    getMgrGroupStoreSummaryReportType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/mgrGroupStoreSummaryReportType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetMessageDeliveryTimeWindow
     * @summary Get 訊息發送時間點
     * @request GET:/api/v3/p/staticOptions/messageDeliveryTimeWindow
     */
    getMessageDeliveryTimeWindow: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/messageDeliveryTimeWindow`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetManagerPermission
     * @summary Get Manager權限
     * @request GET:/api/v3/p/staticOptions/managerPermission
     */
    getManagerPermission: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/managerPermission`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetInvitedStoreCreditStatus
     * @summary Get 商家邀請獎勵狀態
     * @request GET:/api/v3/p/staticOptions/invitedStoreCreditStatus
     */
    getInvitedStoreCreditStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/invitedStoreCreditStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetGroupUserStatus
     * @summary Get GroupUser狀態
     * @request GET:/api/v3/p/staticOptions/groupUserStatus
     */
    getGroupUserStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/groupUserStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetGroupUserRole
     * @summary Get 管理員角色
     * @request GET:/api/v3/p/staticOptions/groupUserRole
     */
    getGroupUserRole: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/groupUserRole`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetGender
     * @summary Get 性別
     * @request GET:/api/v3/p/staticOptions/gender
     */
    getGender: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/gender`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetDeviceStatus
     * @summary Get Device狀態
     * @request GET:/api/v3/p/staticOptions/deviceStatus
     */
    getDeviceStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/deviceStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerVoucherHistoryType
     * @summary Get 票券歷史紀錄類型
     * @request GET:/api/v3/p/staticOptions/consumerVoucherHistoryType
     */
    getConsumerVoucherHistoryType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerVoucherHistoryType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerPaymentStatus
     * @summary Get 會員付款狀態
     * @request GET:/api/v3/p/staticOptions/consumerPaymentStatus
     */
    getConsumerPaymentStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerPaymentStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerOrderPrePayConfig
     * @summary Get 顧客訂金設定
     * @request GET:/api/v3/p/staticOptions/consumerOrderPrePayConfig
     */
    getConsumerOrderPrePayConfig: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerOrderPrePayConfig`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerMembership
     * @summary Get Consumer狀態
     * @request GET:/api/v3/p/staticOptions/consumerMembership
     */
    getConsumerMembership: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerMembership`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerGroupDepositHistoryModifyType
     * @summary Get 儲值金異動類型
     * @request GET:/api/v3/p/staticOptions/consumerGroupDepositHistoryModifyType
     */
    getConsumerGroupDepositHistoryModifyType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerGroupDepositHistoryModifyType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerGroupBonusHistoryModifyType
     * @summary Get 紅利點數紀錄異動類型
     * @request GET:/api/v3/p/staticOptions/consumerGroupBonusHistoryModifyType
     */
    getConsumerGroupBonusHistoryModifyType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerGroupBonusHistoryModifyType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerDocTemplateItemType
     * @summary Get 文件內容種類
     * @request GET:/api/v3/p/staticOptions/consumerDocTemplateItemType
     */
    getConsumerDocTemplateItemType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerDocTemplateItemType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetConsumerAgeLevel
     * @summary Get 會員年齡層
     * @request GET:/api/v3/p/staticOptions/consumerAgeLevel
     */
    getConsumerAgeLevel: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/consumerAgeLevel`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetClerkType
     * @summary Get 店員類型
     * @request GET:/api/v3/p/staticOptions/clerkType
     */
    getClerkType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/clerkType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetBillType
     * @summary Get 帳單類型
     * @request GET:/api/v3/p/staticOptions/billType
     */
    getBillType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/billType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetBillStatus
     * @summary Get 帳單狀態
     * @request GET:/api/v3/p/staticOptions/billStatus
     */
    getBillStatus: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/billStatus`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetBillRef
     * @summary Get 帳單關聯物件類型類型
     * @request GET:/api/v3/p/staticOptions/billRef
     */
    getBillRef: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/billRef`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetBillPaymentType
     * @summary Get 帳單付款類型
     * @request GET:/api/v3/p/staticOptions/billPaymentType
     */
    getBillPaymentType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/billPaymentType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetBillItemRef
     * @summary Get 帳單明細項目類型
     * @request GET:/api/v3/p/staticOptions/billItemRef
     */
    getBillItemRef: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/billItemRef`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetBillDiscountType
     * @summary Get 帳單折扣類型
     * @request GET:/api/v3/p/staticOptions/billDiscountType
     */
    getBillDiscountType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/billDiscountType`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags StaticOptionApi
     * @name GetAccumulationType
     * @summary Get 累計計算方式
     * @request GET:/api/v3/p/staticOptions/accumulationType
     */
    getAccumulationType: (params: RequestParams = {}) =>
      this.request<OptionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/p/staticOptions/accumulationType`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
