interface Between {
  (
    target: number,
    min: number,
    max: number,
    options?: { includeMin?: boolean; includeMax?: boolean }
  ): boolean;
}

export const between: Between = (
  target,
  min,
  max,
  { includeMin = true, includeMax = true } = {
    includeMin: true,
    includeMax: true,
  }
) => {
  return (
    (target > min || (includeMin && target === min)) &&
    (target < max || (includeMax && target === max))
  );
};
