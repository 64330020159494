import { MbrProfileDto } from '@src/swagger/consumer.api';

export const TWODAY_TOKEN_KEY = 'authorization';

export const NOOP = (): any => null;

// export const APPLICATION_MAX_WIDTH = 728;

export const GENDER_TW: Record<NonNullable<MbrProfileDto['gender']>, string> = {
  MALE: '先生',
  FEMALE: '小姐',
  OTHER: '',
};

export const GENDER_OPTIONS: Array<Option> = [
  { key: 'FEMALE', label: '女性' },
  { key: 'MALE', label: '男性' },
  { key: 'OTHER', label: '其他' },
];
