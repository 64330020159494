export const pathname = {
  auth: '/auth',
  startAuth: '/auth/start',
  loading: '/loading',

  mapping: '/',
  result: '/result',
  reserving: (reservationId: number) => `/result/reserving/${reservationId}`,
  profile: '/profile',
  updateProfile: '/profile/update',
  order: '/order',
  incoming: (slotId: string | number) => `incoming/${slotId}`,
};
