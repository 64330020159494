import EE from 'eventemitter3';

const hungerEvent = new EE();

export const loadingEventEmitter = {
  on: (fn: (isLoading: boolean) => void) => hungerEvent.on('loading', fn),
  off: (fn: (isLoading: boolean) => void) => hungerEvent.off('loading', fn),
  emit: (isLoading: boolean) => hungerEvent.emit('loading', isLoading),
};

export const apiErrorExceptionEvent = {
  on: (fn: (error: ApiErrorException) => void) =>
    hungerEvent.on('api-error', fn),
  off: (fn: (error: ApiErrorException) => void) =>
    hungerEvent.off('api-error', fn),
  emit: (error: ApiErrorException) => hungerEvent.emit('api-error', error),
};
