import { staticCities } from '@/pages/api/cities';
import { staticDistricts } from '@/pages/api/districts/[city]';

export const getArea = (addressCity?: string, addressDistrict?: string) => {
  if (!addressCity) return { city: '', district: '' };
  const city = staticCities.find(({ key }) => key === addressCity)?.label;
  if (!addressDistrict) return { city, district: '' };
  const district = staticDistricts[addressCity ?? '']?.find(
    ({ key }) => key === addressDistrict
  )?.label;

  return { city, district };
};
