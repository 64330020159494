import * as dfz from 'date-fns-tz';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import * as process from 'process';

import { isBrowser } from '@src/utils/isBrowser';

if (isBrowser() && process.env.APP_ENV === 'development') {
  // @ts-ignore
  window._ = dfz;
}
export const DAY_NAMES = ['日', '一', '二', '三', '四', '五', '六'];

/**
 * @Description: Offset the date to Taiwan time(But timezone not change)
 */
export const getTaiwanTime = (date: Date = new Date()): Date =>
  utcToZonedTime(isNaN(date.getTime()) ? new Date() : date, 'Asia/Taipei');

/**
 * @Description: Use current time to create a Taiwan time(But timezone not change)
 */
export const createTaiwanDate = (date: Date = new Date()): Date =>
  zonedTimeToUtc(date, 'Asia/Taipei');
