import {
  isNotEmptyArray,
  isNotEmptyString,
  isNumber,
  isSet,
} from '@src/utils/formatChecker';

export const isRequired = (value: undefined | any) =>
  isSet(value) ? undefined : '此欄位為必填。';

export const stringRequired = (value: undefined | string) =>
  isSet(value) && isNotEmptyString(value) ? undefined : '此欄位為必填。';

// noinspection RegExpSimplifiable
export const phoneRequired = (value: undefined | string) =>
  isSet(value) && /^[0-9]{9,10}$/.test(value)
    ? undefined
    : '請填入正確的手機號碼';

// noinspection RegExpSimplifiable
const emailReg =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const emailValidator = (value: undefined | string) =>
  isSet(value) && isNotEmptyString(value)
    ? emailReg.test(value)
      ? undefined
      : '請輸入正確的Email格式。'
    : undefined;

export const emailRequired = (value: undefined | string) =>
  isSet(value) && emailReg.test(value) ? undefined : '請輸入正確的Email格式。';

// noinspection RegExpSimplifiable
export const contactPhoneValidator = (value: undefined | string = '') =>
  /^[0-9]{0,}-?[0-9]{0,}$/.test(value) ? undefined : '請輸入正確的電話號碼。';

export const multipleValueRequired = (value: Array<any> = []) =>
  isNotEmptyArray(value) ? undefined : '至少需要一個選項。';

// noinspection RegExpSimplifiable,RegExpRedundantEscape,RegExpUnnecessaryNonCapturingGroup
const URLReg =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

export const urlValidator = (value: undefined | string = '') =>
  URLReg.test(value) ? undefined : '請輸入正確的網址格式。';

export const digitalOnly = (value: undefined | string | number = 0) =>
  isNumber(value) || /^[0-9]+$/.test(value) ? undefined : '只允許填入數字';
