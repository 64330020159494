import { NextApiResponse } from 'next';

import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import jsCookie from 'js-cookie';
import { v4 as uuidV4 } from 'uuid';

import { redirect } from 'next/dist/server/api-utils';
import { isBrowser } from 'react-use/lib/misc/util';

import { pathname, TWODAY_TOKEN_KEY } from '@src/constant';

import { isNotEmptyArray, storage, StorageProperties } from '@src/utils';

import { apiErrorExceptionEvent, loadingEventEmitter } from '@src/event/event';

import { Api as ConsumerApiInstance } from './consumer.api';
import { Api as PublicApiInstance } from './public.api';

const baseURL = isBrowser
  ? `${window.location.origin}/rewrite/twoday-api`
  : `${process.env.TWODAY_API_URL}`;

const API = axios.create({
  baseURL,
});

const PureAPI = axios.create();

export const ApiLoadingCounter = {
  count: 0,
};

export const requestHandler = (
  config: AxiosRequestConfig
): AxiosRequestConfig | Promise<AxiosRequestConfig> => {
  if (isBrowser) {
    config.apiRequestUuid = uuidV4();
    if (!config.disableAuth) {
      const cookies = jsCookie.get();
      config.headers![TWODAY_TOKEN_KEY] =
        cookies[TWODAY_TOKEN_KEY] ||
        storage.getter(StorageProperties.ACCESS_TOKEN) ||
        '';
    }
  } else {
    if (!config.disableAuth) {
      const req = config.serverRequest;
      const token =
        req?.cookies?.[TWODAY_TOKEN_KEY] || req?.headers?.[TWODAY_TOKEN_KEY];

      if (token) {
        config.headers = {
          [TWODAY_TOKEN_KEY]: token,
        };
      }
    }
  }
  if (!config?.disableLoader) {
    ApiLoadingCounter.count++;
    loadingEventEmitter.emit(true);
  }

  return config;
};

export const responseHandler = (response: AxiosResponse) => {
  if (!response.config?.disableLoader) {
    ApiLoadingCounter.count--;
    loadingEventEmitter.emit(false);
  }
  return response;
};

export const responseErrorHandler = async (
  error: AxiosError<ApiErrorException>
) => {
  if (!error.config?.disableLoader) {
    ApiLoadingCounter.count--;
    loadingEventEmitter.emit(false);
  }
  const data = error.response?.data as any;
  const config = error.config;

  if (!config?.disableAlert) {
    if (isBrowser) {
      const name: string = data?.name || '';
      const messages: Array<string> = data?.messages || [];
      const code: number = data?.code as number;
      if (isNotEmptyArray(messages)) {
        const isBypass = (error?.config?.bypassErrorNames || []).includes(name);
        if (!isBypass && Number(data?.code) !== 401) {
          apiErrorExceptionEvent.emit({
            name,
            code,
            messages,
          });
        }
      }
    }
  }

  if (
    Number(error.response?.status) === 401 &&
    !config.noRedirectAfterAuthFailure
  ) {
    if (isBrowser) {
      return (window.location.href = pathname.auth);
    } else {
      if (config.serverResponse) {
        return redirect(
          config.serverResponse as NextApiResponse,
          pathname.auth
        );
      }
    }
  }

  return Promise.reject(error);
};

API.interceptors.request.use(requestHandler);
API.interceptors.response.use(responseHandler, responseErrorHandler);

PureAPI.interceptors.request.use(requestHandler);
PureAPI.interceptors.response.use(responseHandler, responseErrorHandler);

const Public = new PublicApiInstance();
Public.instance = API;

const Consumer = new ConsumerApiInstance();
Consumer.instance = API;

export const PublicAPI = Public;
export const ConsumerAPI = Consumer;

export { PureAPI };
export default API;
