import '@/src/styles/globals.css';

import { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import NextNProgress from 'nextjs-progressbar';

import { Amplify } from 'aws-amplify';
import classNames from 'classnames';

import * as analytics from '@firebase/analytics';
import { FirebaseOptions, initializeApp } from '@firebase/app';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';

import { isBrowser } from '@src/utils';

import { HistoryContextProvider } from '@src/context/HistoryContext';

import {
  ApplicationContextProvider,
  FirebaseContextProvider,
  LiffContextProvider,
} from '@src/context';

import Flexbox from '@src/component/common/Flexbox';

import awsExports from '../src/aws-exports';

import 'react-toastify/dist/ReactToastify.css';

const DynamicSpinner = dynamic(() => import('@src/component/feature/Spinner'), {
  ssr: false,
});

const firebaseConfig: FirebaseOptions = {
  apiKey: 'AIzaSyAOlC4kphhHapGdMTEpeABLwTDXNi8eKaU',
  authDomain: 'hunger3-5d37c.firebaseapp.com',
  databaseURL: 'https://hunger3-5d37c.firebaseio.com',
  projectId: 'hunger3-5d37c',
  storageBucket: 'hunger3-5d37c.appspot.com',
  messagingSenderId: '872311018943',
  appId: '1:872311018943:web:8ac25263a2a1cea2807d0a',
  measurementId: 'G-C3J3NL22G1',
};
Amplify.configure({
  ssr: true,
  ...awsExports,
});

function MyApp({ Component, pageProps }: AppProps) {
  const [isFirebaseSetup, setIsFirebaseSetup] = useState<boolean>(false);

  useEffect(() => {
    if (!isBrowser()) return;
    console.log('initialize Firebase App');
    const app = initializeApp(firebaseConfig);
    analytics.isSupported().then((isSupported) => {
      if (isSupported) {
        console.log(
          'This environment support firebase analytics, start to get it.'
        );
        analytics.getAnalytics(app);
      }
    });
    setIsFirebaseSetup(true);
  }, []);

  useEffect(() => {
    if (isBrowser() && process.env.APP_ENV !== 'production') {
      console.log(`run on ${process.env.APP_ENV} environment: boost vConsole`);
      const VConsole = require('vconsole');
      new VConsole();
    }
  }, []);

  return (
    <LiffContextProvider>
      <HistoryContextProvider>
        <ApplicationContextProvider>
          <FirebaseContextProvider isFirebaseSetup={isFirebaseSetup}>
            <NextNProgress
              height={5}
              color={'#FF91A3'}
              startPosition={0.3}
              stopDelayMs={200}
              showOnShallow={true}
              nonce={''}
              options={{}}
            />
            <ToastContainer position={'top-center'} />
            <Flexbox className={classNames('w-screen min-h-screen')}>
              <DynamicSpinner />
              <div className={classNames('flex-1 bg-white z-999')} />
              <div
                id={'main'}
                className={classNames(
                  'relative',
                  'min-w-min-screen max-w-max-screen w-full'
                )}
              >
                <Component {...pageProps} />
              </div>
              <div className={classNames('flex-1 bg-white z-999')} />
            </Flexbox>
          </FirebaseContextProvider>
        </ApplicationContextProvider>
      </HistoryContextProvider>
    </LiffContextProvider>
  );
}

// noinspection JSUnusedGlobalSymbols
export default MyApp;
