import { PublicAPI } from '@src/swagger';

export const getServiceCategories = async (categories: Array<string>) => {
  const { data } =
    await PublicAPI.staticOptionApi.getTwodayStoreServiceCategory();

  return data
    .filter((category) => categories.includes(category.key))
    .map((category) => category.label);
};
