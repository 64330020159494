import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useRouter } from 'next/router';

import { NOOP } from '@src/constant';

interface Back {
  (defaultPage: string): Promise<void>;

  (event: React.MouseEvent): Promise<void>;
}

interface HistoryContextStore {
  history: Array<string>;
  back: Back;
  isRouting: boolean;
}

const HistoryContext = createContext<HistoryContextStore>({
  history: [],
  back: NOOP,
  isRouting: false,
});

export const HistoryContextProvider: React.FC = ({ children }) => {
  const [history, setHistory] = useState<Array<string>>([]);
  const [isRouting, setIsRouting] = useState<boolean>(false);

  const { asPath, ...router } = useRouter();

  useEffect(() => {
    setHistory((prev) => prev.concat(asPath));
  }, [asPath]);

  useEffect(() => {
    const onRouteStart = () => setIsRouting(true);
    const onRouteComplete = () => setIsRouting(false);
    router.events.on('routeChangeStart', onRouteStart);
    router.events.on('routeChangeComplete', onRouteComplete);
    router.events.on('routeChangeError', onRouteComplete);

    return () => {
      router.events.off('routeChangeStart', onRouteStart);
      router.events.off('routeChangeComplete', onRouteComplete);
      router.events.off('routeChangeError', onRouteComplete);
    };
  }, []);

  const back = useCallback<Back>(
    async (defaultPath) => {
      let goToPath = typeof defaultPath === 'string' ? defaultPath : undefined;
      if (history.length <= 1 && goToPath) await router.replace(goToPath);
      router.back();
    },
    [history]
  );

  return (
    <HistoryContext.Provider value={{ history, back, isRouting }}>
      {children}
    </HistoryContext.Provider>
  );
};

export const useHistoryContext = () => useContext(HistoryContext);
