// @ts-nocheck
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface MbrBindStoreLiffReq {
  /** Line id for this store(liff) */
  lineId?: string;

  /** Line名稱 */
  lineUserName?: string;

  /** Line照片 */
  lineProfilePicture?: string;
}

/**
 * Logo圖片
 */
export interface FileDto {
  /**
   * id of File
   * @format int32
   */
  id: number;

  /** 檔案名稱 */
  name: string;

  /** 檔案類型 */
  contentType: string;

  /** 檔案所屬物件類型 */
  relateType?:
    | "GROUP"
    | "STORE"
    | "USER"
    | "ORDER"
    | "RESOURCE"
    | "STORE_REPORT"
    | "CMS_CONSUMER_IMPORT"
    | "SIGNATURE"
    | "ORDER_CHECKOUT"
    | "BILL";

  /**
   * 檔案所屬物件編號
   * @format int32
   */
  relateTypeId?: number;

  /** 檔案URL */
  url?: string;
}

/**
 * Group
 */
export interface MbrGroupInOrderDto {
  /** 名稱 */
  name: string;

  /** 網址SubDomain/Path */
  groupDomain: string;
}

export interface MbrStoreDetailDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** hashId */
  hashId: string;

  /** 名稱 */
  name: string;

  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** 營業時間 */
  openTimeDescription: string;

  /** 地址-國家 */
  addressCountry: string;

  /** 地址-城市 */
  addressCity: string;

  /** 地址-地區 */
  addressDistrict: string;

  /** 地址-街道、樓層、號碼 */
  addressDetail: string;

  /** Facebook連結 */
  socialLinkFb?: string;

  /** Instagram連結 */
  socialLinkInstagram?: string;

  /** LINE官方帳號 */
  socialLinkLine?: string;

  /** 電話不顯示 */
  hidePhone: boolean;

  /** 地址不顯示 */
  hideAddress: boolean;

  /** Group */
  group: MbrGroupInOrderDto;

  /** Logo圖片 */
  logoPhotoFile?: FileDto;

  /** Store的liffId */
  liffId?: string;

  /** 會員是否已經在這個Store的liff綁定uid */
  consumerLiffBound?: boolean;
}

export interface MbrUpdateProfileReq {
  /** 名稱 */
  name?: string;

  /**
   * 生日
   * @format date-time
   */
  birthday?: string;

  /** Email */
  email?: string;

  /** 性別（staticOptions.gender） */
  gender?: "MALE" | "FEMALE" | "OTHER";

  /** 主要活動國家（staticOptions.consumerCountry） */
  activeCountry?: string;

  /** 主要活動城市 */
  activeCity?: string;
  groupDomain?: string;
}

/**
 * 已綁定Groups
 */
export interface MbrGroupInProfileDto {
  /** 名稱 */
  name: string;

  /** 網址SubDomain/Path */
  groupDomain: string;

  /**
   * Logo圖片Id
   * @format int32
   */
  logoPhotoFileId?: number;

  /**
   * 主圖片Id
   * @format int32
   */
  mainPhotoFileId?: number;

  /** Logo圖片 */
  logoPhotoFile?: FileDto;
}

export interface MbrProfileDto {
  /** 名稱 */
  name?: string;

  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /**
   * 生日
   * @format date-time
   */
  birthday?: string;

  /** Email */
  email?: string;

  /**
   * 評分(全平台)
   * @format double
   */
  platformScore?: number;

  /** 性別（staticOptions.gender） */
  gender?: "MALE" | "FEMALE" | "OTHER";

  /** 主要活動國家（staticOptions.consumerCountry） */
  activeCountry?: string;

  /** 主要活動城市 */
  activeCity?: string;

  /**
   * 我的預約數
   * @format int32
   */
  waitingOrderCount: number;

  /**
   * 票券數
   * @format int32
   */
  voucherCount: number;

  /** 已綁定Groups */
  boundGroups: MbrGroupInProfileDto[];

  /** Logo圖片 */
  avatarPhotoFile?: FileDto;

  /**
   * 紅利點數
   * @format double
   */
  groupBonusPoint: number;

  /**
   * 儲值金
   * @format double
   */
  groupDepositPoint: number;

  /** 是否需要填寫註冊資訊 */
  signUpRequired?: boolean;

  /**
   * 消費者未填寫問卷與同意書數量
   * @format int32
   */
  unSignedConsumerDocCount: number;
}

export interface MbrBindTwodayLineLiffReq {
  /** line id */
  lineId?: string;

  /** line user name */
  lineUserName?: string;

  /** line profile picture */
  lineProfilePicture?: string;
}

export interface MbrLmpUnBindReq {
  /** group domain */
  groupDomain?: string;
}

export interface MbrLmpBindReq {
  /** group domain */
  groupDomain?: string;

  /** lmp action token */
  lmpToken: string;
}

export interface MbrBindGroupLineIdReq {
  /** group domain */
  groupDomain: string;

  /** line id */
  lineId: string;

  /** line user name */
  lineUserName?: string;

  /** line profile picture */
  lineProfilePicture?: string;
}

export interface MbrUpdateAvatarPhotoReq {
  /**
   * 頭像照片Id
   * @format int32
   */
  avatarPhotoFileId?: number;
}

export interface CommitFileUploadReq {
  /**
   * id of File
   * @format int32
   */
  id: number;

  /** 檔案所屬物件類型 */
  relateType?:
    | "GROUP"
    | "STORE"
    | "USER"
    | "ORDER"
    | "RESOURCE"
    | "STORE_REPORT"
    | "CMS_CONSUMER_IMPORT"
    | "SIGNATURE"
    | "ORDER_CHECKOUT"
    | "BILL";

  /**
   * 檔案所屬物件編號
   * @format int32
   */
  relateTypeId?: number;
}

export interface MbrDocTemplateItemReq {
  /**
   * 問卷題目排序
   * @format int32
   */
  sort: number;
  questionType: "SINGLE" | "MULTIPLE" | "SHORT_ANSWER" | "DESCRIPTION";

  /** 問卷題目是否必填 */
  required: "ON" | "OFF";

  /** 問卷題目文字(題目) = ConsumerDocTemplateItem.content */
  questionText: string;

  /** 問卷題目回答答案 */
  shortAnswer?: string;

  /** 問卷題目文字(全部選項回傳) */
  optionsText?: string[];

  /** 問卷題目選擇選項 */
  options?: number[];
}

export interface MbrFillConsumerDocReq {
  /** 文件標題 */
  docTitle: string;
  itemList: MbrDocTemplateItemReq[];

  /** @format int32 */
  signaturePathFileId?: number;

  /** @format int32 */
  signatureImageFileId?: number;
}

export interface MbrConsumerDocDto {
  /**
   * id for consumerDoc
   * @format int32
   */
  id: number;

  /**
   * created at
   * @format date-time
   */
  createdAt: string;

  /** @format int32 */
  consumerId: number;

  /** @format int32 */
  docTemplateId: number;

  /** @format int32 */
  fileId?: number;
  fileName?: string;

  /** @format int32 */
  signatureId?: number;
  filled: boolean;

  /** @format date-time */
  fillAt?: string;
  signaturePublicKey?: string;

  /** Logo圖片 */
  fillDocFile?: FileDto;
}

export interface MbrBindGroupReq {
  /** 網址SubDomain/Path */
  groupDomain: string;
}

export interface MbrSearchResourceAvailableSlotReq {
  /** 時間區間 */
  time: SearchDateTimeRange;

  /**
   * 預約數量
   * @format int32
   */
  amount: number;

  /**
   * 可預約時間間隔(分)
   * @format int32
   */
  stepInMinutes?: number;

  /**
   * 服務時間
   * @format int32
   */
  totalServiceMinute: number;

  /** 服務項目（用以檢查設備是否佔用） */
  serviceIds: number[];
}

/**
 * 時間區間
 */
export interface SearchDateTimeRange {
  /**
   * 開始時間
   * @format date-time
   */
  from?: string;

  /**
   * 結束時間（不包含）
   * @format date-time
   */
  until?: string;
}

export interface MbrResourceAvailableSlotDto {
  /**
   * 時間
   * @format date-time
   */
  time: string;

  /**
   * 可預約數量
   * @format int32
   */
  availableAmount: number;
}

export interface MbrSearchTwodayStoreReq {
  /** 地址-城市 */
  addressCity?: string;

  /** 地址-地區 */
  addressDistrict?: string;

  /** 服務類型 */
  serviceCategories?: ("A" | "B" | "C" | "D" | "E" | "F" | "G" | "H" | "I")[];

  /** 分頁 */
  paging: PagingRequest;

  /** 排序 */
  sorts?: SortField[];
}

/**
 * 分頁
 */
export interface PagingRequest {
  /**
   * 頁次（從1開始）
   * @format int32
   */
  page: number;

  /**
   * 每頁數量
   * @format int32
   */
  pageSize: number;
}

/**
 * 排序
 */
export interface SortField {
  /** 排序方式 */
  sort?: "ASC" | "DESC";

  /** 排序欄位 */
  field?: string;
}

export interface MbrTwodayStoreDto {
  /** @format int32 */
  storeId: number;

  /** Twoday中的商家介紹 */
  description: string;

  /** Twoday中的商家名稱 */
  name: string;

  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** 地址-國家 */
  addressCountry: string;

  /** 地址-城市 */
  addressCity: string;

  /** 地址-地區 */
  addressDistrict: string;

  /** 地址-街道、樓層、號碼 */
  addressDetail: string;

  /** 電話不顯示 */
  hidePhone: boolean;

  /** FB */
  socialLinkFb?: string;

  /** IG */
  socialLinkInstagram?: string;

  /** Line */
  socialLinkLine?: string;

  /** Google Map */
  googleMap?: string;

  /** 注意事項 */
  noticeDescriptionStatus: "ON" | "OFF";

  /** 注意事項 */
  noticeDescription: string;

  /** 服務類型 */
  serviceCategories: ("A" | "B" | "C" | "D" | "E" | "F" | "G" | "H" | "I")[];

  /** Logo圖片 */
  mainPhotoFile?: FileDto;
}

export interface PageDtoMbrTwodayStoreDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrTwodayStoreDto[];

  /** @format int64 */
  totalCount: number;
}

export interface MbrSearchTwodayStoreWithSlotReq {
  /** 地址-城市 */
  addressCity: string;

  /** 地址-地區 */
  addressDistrict?: string;

  /** 服務類型 */
  serviceCategories: ("A" | "B" | "C" | "D" | "E" | "F" | "G" | "H" | "I")[];

  /** 時間區間 */
  time: SearchDateTimeRange;
}

/**
 * 預約組合
 */
export interface MbrTwodayStoreReservationSetWithSlotDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 組合名稱 */
  name: string;

  /**
   * 定價
   * @format double
   */
  price: number;

  /**
   * 服務時間
   * @format int32
   */
  serviceMinutes: number;

  /** Logo圖片 */
  photoFile?: FileDto;

  /**
   * 折扣數 80=8折
   * @format int32
   */
  discount: number;

  /** 可預約時間 */
  slots: TwodayAvailableSlotDto[];
}

export interface MbrTwodayStoreWithSlotDto {
  /** @format int32 */
  storeId: number;

  /** Twoday中的商家名稱 */
  name: string;

  /** 地址-城市 */
  addressCity: string;

  /** 地址-地區 */
  addressDistrict: string;

  /** Logo圖片 */
  mainPhotoFile?: FileDto;

  /** 預約組合 */
  reservationSets: MbrTwodayStoreReservationSetWithSlotDto[];
}

/**
 * 可預約時間
 */
export interface TwodayAvailableSlotDto {
  /** @format date-time */
  time: string;

  /** @format int32 */
  availableAmount: number;
}

export interface MbrTwodayGetReservationSetWithSlotDetailReq {
  /** 時間區間 */
  time: SearchDateTimeRange;
}

/**
 * 服務
 */
export interface MbrTwodayServiceInReservationSetDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name?: string;

  /**
   * 時間長度
   * @format int32
   */
  serviceMinutes: number;

  /** 是否開啟服務介紹 */
  descriptionEnabled: boolean;

  /** 服務介紹 */
  description?: string;

  /** Sub Service */
  subServices: MbrTwodaySubServiceDto[];

  /** Logo圖片 */
  servicePhotoFile?: FileDto;
}

export interface MbrTwodayStoreReservationSetWithSlotDetailDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 組合名稱 */
  name: string;

  /** 組合介紹 */
  description: string;

  /**
   * 定價
   * @format double
   */
  price: number;

  /**
   * 服務時間
   * @format int32
   */
  serviceMinutes: number;

  /** 服務 */
  serviceIds: number[];

  /** 設計師 */
  resourceIds: number[];

  /** Logo圖片 */
  photoFile?: FileDto;

  /**
   * 折扣數 80=8折
   * @format int32
   */
  discount: number;

  /** 可預約時間 */
  slots: TwodayAvailableSlotDto[];
  store?: MbrTwodayStoreDto;

  /** 服務 */
  services?: MbrTwodayServiceInReservationSetDto[];
}

/**
 * Sub Service
 */
export interface MbrTwodaySubServiceDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name: string;

  /**
   * 時間長度（分）
   * @format int32
   */
  timeInMinutes: number;
}

export interface PagingAndSortingRequest {
  /** 分頁 */
  paging: PagingRequest;

  /** 排序 */
  sorts?: SortField[];
}

/**
 * 訂金狀態
 */
export interface MbrOrderPrePayDto {
  /**
   * 付款期限
   * @format date-time
   */
  expireAt?: string;

  /** 回報付款帳號 */
  payAccount?: string;

  /**
   * 回報時間
   * @format date-time
   */
  reportAt?: string;

  /**
   * 應付款金額
   * @format double
   */
  amountToPay?: number;

  /** 指定付款方式, null 表示可讓客戶自行選擇 */
  specifyPaymentType?: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";
}

/**
 * Resource
 */
export interface MbrResourceInOrderDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 設計師名稱 */
  name: string;

  /** 服務數量單位 */
  unit: string;
}

export interface MbrTwodayOrderDto {
  /**
   * 建立時間
   * @format date-time
   */
  createdAt: string;

  /** 狀態（staticOptions.orderStatus） */
  status: "DRAFT" | "WAITING" | "CHECKED_IN" | "CANCEL" | "CONSUMER_CANCEL" | "NO_SHOW";

  /** 訂金狀態（staticOptions.prePayStatus） */
  prePayStatus?: "WAITING" | "REPORT_PAYED" | "CHECKED";

  /** 短網址編號 */
  shortId: string;

  /**
   * 服務時間(分)
   * @format int32
   */
  totalServiceMinute: number;

  /**
   * 消費金額
   * @format double
   */
  price: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /** 其他需求(顧客) */
  mbrExtraDemand?: string;

  /** Resource */
  resource: MbrResourceInOrderDto;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 不指定預約內容 */
  unSpecifyResourceGroup?: SimpleUnSpecifyResourceGroupDto;
  twodayStore?: MbrTwodayStoreDto;

  /** 訂金狀態 */
  orderPrePay?: MbrOrderPrePayDto;

  /** 服務清單 */
  serviceSummaryList: OrderServiceSummaryDto[];

  /** Twoday預約組合Id */
  twodayInfo?: MbrTwodayOrderTwodayInfoDto;
}

/**
 * Twoday預約組合Id
 */
export interface MbrTwodayOrderTwodayInfoDto {
  /**
   * 預約組合的折扣前金額
   * @format double
   */
  priceOriginal: number;

  /** 預約組合名稱 */
  reservationSetName: string;

  /**
   * 預約組合編號
   * @format int32
   */
  reservationSetId: number;
}

/**
 * 服務清單
 */
export interface OrderServiceSummaryDto {
  /** @format int32 */
  categoryId: number;
  categoryName: string;

  /** @format int32 */
  serviceId: number;
  serviceName: string;
  subServices: string[];
}

export interface PageDtoMbrTwodayOrderDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrTwodayOrderDto[];

  /** @format int64 */
  totalCount: number;
}

/**
 * 不指定預約內容
 */
export interface SimpleUnSpecifyResourceGroupDto {
  /**
   * id
   * @format int32
   */
  id: number;
  name: string;
}

export interface MbrResourceDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 設計師名稱 */
  name: string;

  /** 預約注意事項 */
  noticeDescription: string;

  /** Logo圖片 */
  logoPhotoFile?: FileDto;
}

export interface MbrCreateOrderReq {
  /**
   * 服務時間(分)
   * @format int32
   */
  totalServiceMinute: number;

  /**
   * 數量
   * @format int32
   */
  amount: number;

  /**
   * 消費金額
   * @format double
   */
  price: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /**
   * 結束時間
   * @format date-time
   */
  endAt: string;

  /** 性別（staticOptions.gender） */
  consumerGender?: "MALE" | "FEMALE" | "OTHER";

  /** 其他需求(顧客) */
  mbrExtraDemand?: string;

  /**
   * Resource
   * @format int32
   */
  resourceId: number;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 顧客姓名 */
  consumerNameInOrder?: string;

  /** 服務清單 */
  serviceIds: number[];

  /** 服務清單(SubService) */
  subServiceIds?: number[];

  /** 問題回答清單 */
  questionReplies: PadSaveOrderResourceQuestionReq[];

  /** Recaptcha token */
  recaptchaToken?: string;

  /**
   * Twoday預約組合Id
   * @format int32
   */
  twodayReservationSetId?: number;
}

/**
 * 問題回答清單
 */
export interface PadSaveOrderResourceQuestionReq {
  /**
   * 問題
   * @format int32
   */
  questionId: number;

  /** 回答 */
  reply: string[];
}

export interface MbrOrderDetailDto {
  /**
   * 建立時間
   * @format date-time
   */
  createdAt: string;

  /** 狀態（staticOptions.orderStatus） */
  status: "DRAFT" | "WAITING" | "CHECKED_IN" | "CANCEL" | "CONSUMER_CANCEL" | "NO_SHOW";

  /** 訂金狀態（staticOptions.prePayStatus） */
  prePayStatus?: "WAITING" | "REPORT_PAYED" | "CHECKED";

  /** 短網址編號 */
  shortId: string;

  /** 訂單建立方式（staticOptions.orderCreationType） */
  creationType: "PAD" | "MOBILE" | "ONLINE" | "RM" | "TWODAY";

  /**
   * 服務時間(分)
   * @format int32
   */
  totalServiceMinute: number;

  /**
   * 數量
   * @format int32
   */
  amount: number;

  /**
   * 消費金額
   * @format double
   */
  price: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /**
   * 結束時間
   * @format date-time
   */
  endAt: string;

  /** 性別（staticOptions.gender） */
  consumerGender?: "MALE" | "FEMALE" | "OTHER";

  /** 其他需求(顧客) */
  mbrExtraDemand?: string;

  /** Resource */
  resource: MbrResourceInOrderDto;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 不指定預約內容 */
  unSpecifyResourceGroup?: SimpleUnSpecifyResourceGroupDto;

  /** 顧客姓名 */
  consumerNameInOrder?: string;

  /** Store */
  store: MbrStoreInOrderDto;

  /** 服務清單 */
  services: MbrServiceInOrderDto[];

  /** 問題回答清單 */
  questionReplies: MbrOrderQuestionReplyDto[];

  /** 是否可以取消 */
  cancelableForMbr?: boolean;
}

/**
 * 問題回答清單
 */
export interface MbrOrderQuestionReplyDto {
  /** 問題 */
  question: MbrResourceQuestionInOrderDto;

  /** 回答 */
  reply: string[];
}

/**
 * 問題
 */
export interface MbrResourceQuestionInOrderDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 問題 */
  question?: string;
}

/**
 * 服務清單
 */
export interface MbrServiceInOrderDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name?: string;

  /**
   * 時間長度
   * @format int32
   */
  serviceMinutes: number;

  /**
   * 價格
   * @format double
   */
  price: number;

  /** 價格不顯示 */
  priceHidden: boolean;

  /** 是否為底標價 */
  priceMarkStarting: boolean;

  /** Sub Service */
  subServices: MbrSubServiceDto[];

  /** Category Name */
  categoryName?: string;
}

/**
 * Store
 */
export interface MbrStoreInOrderDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name: string;

  /** 電話不顯示 */
  hidePhone: boolean;

  /** 地址不顯示 */
  hideAddress: boolean;

  /** Group */
  group: MbrGroupInOrderDto;
}

/**
 * Sub Service
 */
export interface MbrSubServiceDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name: string;

  /**
   * 時間長度（分）
   * @format int32
   */
  timeInMinutes: number;

  /**
   * 價格
   * @format double
   */
  price: number;
}

/**
 * 信用卡資訊, paymentType=CREDIT_CARD時必填
 */
export interface CreditCardInfoDto {
  /** 信用卡號 */
  cardNumber: string;

  /** 信用卡安全碼 */
  cardSecurityCode: string;

  /**
   * 信用卡到期日-年
   * @format int32
   */
  expiryYear: number;

  /**
   * 信用卡到期日-月
   * @format int32
   */
  expiryMonth: number;
}

export interface MbrOrderPrePayPaymentReq {
  /** 付款類型 */
  paymentType: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";

  /** 付款流程結束後，要導向的前端網址 */
  redirectUrl: string;

  /** 信用卡資訊, paymentType=CREDIT_CARD時必填 */
  cardInfo?: CreditCardInfoDto;
}

export interface MbrOrderPrePayPaymentRes {
  nextAction: "REDIRECT_TO_URL" | "COMPLETE";

  /** nextAction=COMPLETE 時，可直接跳轉回訂單 */
  orderShortId: string;

  /** nextAction=REDIRECT_TO_URL 時，前端要跳轉的網址 */
  nextUrl?: string;
}

export interface MbrSearchOrderReq {
  /** group domain */
  groupDomain?: string;

  /** 分頁 */
  paging: PagingRequest;

  /** 排序 */
  sorts?: SortField[];
}

export interface MbrOrderWithPrePayDto {
  /**
   * 建立時間
   * @format date-time
   */
  createdAt: string;

  /** 狀態（staticOptions.orderStatus） */
  status: "DRAFT" | "WAITING" | "CHECKED_IN" | "CANCEL" | "CONSUMER_CANCEL" | "NO_SHOW";

  /** 訂金狀態（staticOptions.prePayStatus） */
  prePayStatus?: "WAITING" | "REPORT_PAYED" | "CHECKED";

  /** 短網址編號 */
  shortId: string;

  /**
   * 數量
   * @format int32
   */
  amount: number;

  /**
   * 消費金額
   * @format double
   */
  price: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /**
   * 結束時間
   * @format date-time
   */
  endAt: string;

  /** Resource */
  resource: MbrResourceInOrderDto;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 不指定預約內容 */
  unSpecifyResourceGroup?: SimpleUnSpecifyResourceGroupDto;

  /** Store */
  store: MbrStoreInOrderDto;

  /** 服務清單 */
  services: MbrServiceInOrderDto[];

  /** 訂金狀態 */
  orderPrePay?: MbrOrderPrePayDto;
}

export interface PageDtoMbrOrderWithPrePayDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrOrderWithPrePayDto[];

  /** @format int64 */
  totalCount: number;
}

export interface MbrOrderDto {
  /**
   * 建立時間
   * @format date-time
   */
  createdAt: string;

  /** 狀態（staticOptions.orderStatus） */
  status: "DRAFT" | "WAITING" | "CHECKED_IN" | "CANCEL" | "CONSUMER_CANCEL" | "NO_SHOW";

  /** 訂金狀態（staticOptions.prePayStatus） */
  prePayStatus?: "WAITING" | "REPORT_PAYED" | "CHECKED";

  /** 短網址編號 */
  shortId: string;

  /**
   * 數量
   * @format int32
   */
  amount: number;

  /**
   * 消費金額
   * @format double
   */
  price: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /**
   * 結束時間
   * @format date-time
   */
  endAt: string;

  /** Resource */
  resource: MbrResourceInOrderDto;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 不指定預約內容 */
  unSpecifyResourceGroup?: SimpleUnSpecifyResourceGroupDto;

  /** Store */
  store: MbrStoreInOrderDto;

  /** 服務清單 */
  services: MbrServiceInOrderDto[];
}

export interface PageDtoMbrOrderDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrOrderDto[];

  /** @format int64 */
  totalCount: number;
}

/**
 * 操作店員
 */
export interface MbrClerkDto {
  /** 名稱 */
  name: string;
}

/**
 * 票券
 */
export interface MbrConsumerVoucherDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /**
   * 建立時間
   * @format date-time
   */
  createdAt: string;

  /** 票券設定 */
  voucherTemplate: MbrVoucherTemplateDto;

  /**
   * 使用期限 - 開始
   * @format date-time
   */
  availableStartAt?: string;

  /**
   * 使用期限 - 結束
   * @format date-time
   */
  availableEndAt?: string;

  /**
   * 發放量
   * @format int32
   */
  initialCount: number;

  /**
   * 使用量
   * @format int32
   */
  usedCount: number;

  /**
   * 收回量
   * @format int32
   */
  retrieveCount: number;

  /**
   * 可用量
   * @format int32
   */
  availableCount: number;

  /**
   * 過期量
   * @format int32
   */
  expiredCount: number;
}

export interface MbrConsumerVoucherHistoryDto {
  /** 操作店員 */
  byClerk?: MbrClerkDto;

  /** 紀錄類型(staticOptions.consumerVoucherHistoryType) */
  type:
    | "SEND"
    | "RETRIEVE"
    | "USED"
    | "EXPIRED"
    | "ROLLBACK"
    | "PURCHASE"
    | "PURCHASE_ROLLBACK"
    | "TRANSFER_OUT"
    | "TRANSFER_IN"
    | "BONUS_EXCHANGE";

  /**
   * 時間
   * @format date-time
   */
  createdAt: string;

  /** 使用分店 */
  clerkStore?: MbrStoreDto;

  /** 紀錄票券內容 */
  voucherHistoryContent?: MbrVoucherHistoryContentDto[];
}

/**
 * 使用分店
 */
export interface MbrStoreDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** hashId */
  hashId: string;

  /** 名稱 */
  name: string;

  /** 營業時間 */
  openTimeDescription: string;

  /** 地址-國家 */
  addressCountry: string;

  /** 地址-城市 */
  addressCity: string;

  /** 地址-地區 */
  addressDistrict: string;

  /** 地址-街道、樓層、號碼 */
  addressDetail: string;

  /** 電話不顯示 */
  hidePhone: boolean;

  /** 地址不顯示 */
  hideAddress: boolean;

  /** Logo圖片 */
  logoPhotoFile?: FileDto;
}

/**
 * 紀錄票券內容
 */
export interface MbrVoucherHistoryContentDto {
  /**
   * 數量
   * @format int32
   */
  count: number;

  /** 票券 */
  consumerVoucher?: MbrConsumerVoucherDto;
}

/**
 * 票券設定
 */
export interface MbrVoucherTemplateDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 票券可使用分店類型(staticOptions.voucherTemplateStoreUsableType) */
  storeUsableType: "ALL_STORES" | "SPECIFY_STORES";

  /** 票券類型(staticOptions.voucherType) */
  voucherType: "NORMAL" | "BONUS_EXCHANGE" | "JOIN_MEMBER" | "BIRTHDAY" | "AMOUNT_FULFILL" | "LTNS";

  /** 票券使用期限類型(staticOptions.voucherExpiration) */
  voucherExpiration: "NONE" | "DAYS" | "DAY_RANGE";

  /** 票券轉贈(staticOptions.onOffStatus) */
  voucherGiftType: "ON" | "OFF";

  /** 票券名稱 */
  name: string;

  /**
   * 可發送起始日
   * @format date-time
   */
  sendableStartFrom?: string;

  /**
   * 可發送結束日
   * @format date-time
   */
  sendableEndUntil?: string;

  /**
   * 期限(天數)
   * @format int32
   */
  expireDays?: number;

  /**
   * 期限(區間) - 開始
   * @format date-time
   */
  expireStartFrom?: string;

  /**
   * 期限(區間) - 結束
   * @format date-time
   */
  expireEndUntil?: string;

  /** 票券內容 */
  description: string;

  /**
   * 紅利兌換所需點數
   * @format int32
   */
  bonusRequiredForExchange?: number;

  /** 使用分店 */
  stores?: MbrStoreDto[];
}

export interface PageDtoMbrConsumerVoucherHistoryDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrConsumerVoucherHistoryDto[];

  /** @format int64 */
  totalCount: number;
}

export interface MbrGetVoucherTransferTargetReq {
  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;
}

export interface MbrVoucherTransferTargetDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name?: string;

  /** 電話號碼 */
  phone: string;

  /** 電話國碼 */
  phoneCountryCode: string;

  /** Logo圖片 */
  avatarPhotoFile?: FileDto;
}

export interface MbrExchangeVoucherByBonusReq {
  /** @format int32 */
  voucherTemplateId: number;

  /** @format int32 */
  cnt: number;
}

export interface MbrTransferVoucherReq {
  /** 贈送票券清單 */
  transferVouchers: MbrVoucherToTransferDto[];

  /**
   * 消費者id
   * @format int32
   */
  consumerId: number;
}

/**
 * 贈送票券清單
 */
export interface MbrVoucherToTransferDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /**
   * 當次贈送數量
   * @format int32
   */
  count: number;
}

export interface MbrConsumerGroupDepositHistoryDto {
  /**
   * 異動儲值點數
   * @format double
   */
  modifyDepositPoint: number;

  /**
   * 紀錄時間
   * @format date-time
   */
  createdAt: string;

  /** 點數異動類型 */
  modifyType:
    | "ORDER_CHECKOUT"
    | "ORDER_CHECKOUT_MODIFY"
    | "PAD_MODIFY"
    | "CMS_UPLOAD"
    | "BILL_PAYMENT"
    | "BILL_PAYMENT_ROLLBACK"
    | "BILL_PURCHASE"
    | "BILL_PURCHASE_ROLLBACK"
    | "ORDER_PREPAY";

  /**
   * 購買金額
   * @format double
   */
  purchasePrice?: number;

  /**
   * 帳單編號
   * @format int32
   */
  billId?: number;

  /** 使用分店 */
  store?: MbrStoreDto;
}

export interface PageDtoMbrConsumerGroupDepositHistoryDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrConsumerGroupDepositHistoryDto[];

  /** @format int64 */
  totalCount: number;
}

export interface MbrConsumerGroupBonusHistoryDto {
  /**
   * 異動紅利點數
   * @format double
   */
  modifyBonusPoint: number;

  /**
   * 紀錄時間
   * @format date-time
   */
  createdAt: string;

  /** 點數異動類型(staticOptions.consumerGroupBonusHistoryModifyType) */
  modifyType: "ORDER" | "PAD_MODIFY" | "CMS_UPLOAD" | "BILL" | "BILL_ROLLBACK" | "VOUCHER_EXCHANGE";

  /**
   * 帳單編號
   * @format int32
   */
  billId?: number;

  /** 使用分店 */
  store?: MbrStoreDto;
}

export interface PageDtoMbrConsumerGroupBonusHistoryDto {
  /** @format int32 */
  atPage: number;

  /** @format int32 */
  totalPages: number;
  items: MbrConsumerGroupBonusHistoryDto[];

  /** @format int64 */
  totalCount: number;
}

export interface IssueFileUploadReq {
  /** 檔案名稱 */
  name: string;

  /** 檔案類型 */
  contentType: string;
}

export interface IssueFileUploadResult {
  /**
   * id of File
   * @format int32
   */
  id: number;

  /** 上傳URL */
  uploadUrl: string;
}

/**
 * 主要服務類別
 */
export interface MbrCategoryInResourceDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name?: string;

  /** Services In Category */
  services: MbrServiceInResourceDto[];
}

/**
 * Services In Category
 */
export interface MbrServiceInResourceDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 名稱 */
  name?: string;

  /**
   * 時間長度
   * @format int32
   */
  serviceMinutes: number;

  /**
   * 價格
   * @format double
   */
  price: number;

  /** 是否開啟服務介紹 */
  descriptionEnabled: boolean;

  /** 服務介紹 */
  description?: string;

  /** 價格不顯示 */
  priceHidden: boolean;

  /** 是否為底標價 */
  priceMarkStarting: boolean;

  /** SubService選擇類型 */
  subServiceOptionType: "SINGLE" | "MULTIPLE";

  /** Sub Service */
  subServices: MbrSubServiceDto[];

  /** Logo圖片 */
  servicePhotoFile?: FileDto;
}

export interface MbrUnSpecifyResourceGroupDetailDto {
  /**
   * id
   * @format int32
   */
  id: number;
  name: string;

  /** 主要服務 */
  primaryServices: MbrServiceInResourceDto[];

  /** 次要服務 */
  secondaryServices: MbrServiceInResourceDto[];

  /** 主要服務類別 */
  primaryServicesByCategory: MbrCategoryInResourceDto[];
}

export interface MbrResourceRequirePrePayInfoDto {
  /** 開啟狀態 */
  status: "ON" | "OFF";

  /** 會員預設狀態是否收取 */
  statusForConsumer: "ON" | "OFF";

  /**
   * 幾日內付款
   * @format int32
   */
  payInDays: number;

  /** 銀行 */
  bank?: string;

  /** 銀行帳號 */
  account?: string;

  /**
   * 訂金金額(舊)
   * @format double
   */
  prePayAmount?: number;

  /** 訂金說明 */
  description?: string;

  /** 目前登入的會員的訂金設定 */
  prepayConfig?: "BY_STORE" | "SKIP" | "FORCE";

  /**
   * 需要付款的訂金金額
   * @format int32
   */
  amountToPay?: number;
}

export interface MbrTwodayOrderDetailDto {
  /** 狀態（staticOptions.orderStatus） */
  status: "DRAFT" | "WAITING" | "CHECKED_IN" | "CANCEL" | "CONSUMER_CANCEL" | "NO_SHOW";

  /** 訂金狀態（staticOptions.prePayStatus） */
  prePayStatus?: "WAITING" | "REPORT_PAYED" | "CHECKED";

  /** 短網址編號 */
  shortId: string;

  /**
   * 服務時間(分)
   * @format int32
   */
  totalServiceMinute: number;

  /**
   * 數量
   * @format int32
   */
  amount: number;

  /**
   * 消費金額
   * @format double
   */
  price: number;

  /**
   * 開始時間
   * @format date-time
   */
  startAt: string;

  /**
   * 結束時間
   * @format date-time
   */
  endAt: string;

  /** 性別（staticOptions.gender） */
  consumerGender?: "MALE" | "FEMALE" | "OTHER";

  /** 其他需求(顧客) */
  mbrExtraDemand?: string;

  /** Resource */
  resource: MbrResourceInOrderDto;

  /**
   * 不指定預約組合Id
   * @format int32
   */
  unSpecifyResourceGroupId?: number;

  /** 不指定預約內容 */
  unSpecifyResourceGroup?: SimpleUnSpecifyResourceGroupDto;

  /** 顧客姓名 */
  consumerNameInOrder?: string;
  twodayStore?: MbrTwodayStoreDto;

  /** 問題回答清單 */
  questionReplies: MbrOrderQuestionReplyDto[];

  /** 訂金狀態 */
  orderPrePay?: MbrOrderPrePayDto;

  /** 是否可以取消 */
  cancelableForMbr?: boolean;

  /** 服務清單 */
  serviceSummaryList: OrderServiceSummaryDto[];

  /** Twoday預約組合Id */
  twodayInfo?: MbrTwodayOrderTwodayInfoDto;
}

export interface MbrStoreOrderAnnouncementDto {
  status: "ON" | "OFF";
  content: string;
}

export interface MbrResourcesForReservationDto {
  /** 設計師清單 */
  resources: MbrResourceDto[];

  /** 不指定設計師組合清單 */
  unSpecifyResourceGroups: MbrUnSpecifyResourceGroupDto[];
}

/**
 * 不指定設計師組合清單
 */
export interface MbrUnSpecifyResourceGroupDto {
  /**
   * id
   * @format int32
   */
  id: number;
  name: string;
}

export interface MbrResourceDetailDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 設計師名稱 */
  name: string;

  /** 服務數量單位 */
  unit: string;

  /** 預約注意事項 */
  noticeDescription: string;

  /**
   * 網路開放最小數量
   * @format int32
   */
  onlineReservationMinCount?: number;

  /**
   * 網路開放最大數量
   * @format int32
   */
  onlineReservationMaxCount?: number;

  /** 網路開放-From（staticOptions.resourceOnlineReservationFrom） */
  onlineReservationFrom?:
    | "NONE"
    | "MIN_10"
    | "MIN_30"
    | "MIN_60"
    | "MIN_90"
    | "MIN_120"
    | "MIN_180"
    | "DAY_1"
    | "DAY_2"
    | "DAY_3"
    | "DAY_4"
    | "DAY_5"
    | "DAY_6"
    | "WEEK_1";

  /** 網路開放-Until（staticOptions.resourceOnlineReservationUntil） */
  onlineReservationUntil?: "NONE" | "MONTH_12" | "MONTH_6" | "MONTH_5" | "MONTH_4" | "MONTH_3" | "MONTH_2" | "MONTH_1";

  /** 網路開放-Until-模式（staticOptions.resourceOnlineReservationUntilMode） */
  onlineReservationUntilMode?: "PERIOD" | "SPECIFY";

  /**
   * 網路開放-Until-開放日期 (每月X日開放)
   * @format int32
   */
  onlineReservationUntilPeroidStartDate?: number;

  /** 網路開放-Until-開放時間（staticOptions.resourceOnlineReservationUntilPeroid） */
  onlineReservationUntilPeroid?: "MONTH_1" | "MONTH_2" | "MONTH_3" | "MONTH_4" | "MONTH_5" | "MONTH_6" | "ALL";

  /** 備註 */
  remarks: string[];

  /** 主要服務 */
  primaryServices: MbrServiceInResourceDto[];

  /** 次要服務 */
  secondaryServices: MbrServiceInResourceDto[];

  /** 主要服務類別 */
  primaryServicesByCategory: MbrCategoryInResourceDto[];
}

export interface MbrResourceQuestionDto {
  /**
   * id
   * @format int32
   */
  id: number;

  /** 問題 */
  question?: string;

  /** 類型（staticOptions.resourceQuestionOptionType） */
  optionType: "SINGLE" | "MULTIPLE" | "COMMENT";

  /** 填寫（staticOptions.resourceQuestionOptionRequireType） */
  optionRequireType: "OPTIONAL" | "REQUIRED";

  /** 答案 */
  answers: string[];
}

export interface MbrOrderPrePayDetailDto {
  /**
   * 付款期限
   * @format date-time
   */
  expireAt?: string;

  /** 回報付款帳號 */
  payAccount?: string;

  /**
   * 回報時間
   * @format date-time
   */
  reportAt?: string;

  /**
   * 應付款金額
   * @format double
   */
  amountToPay?: number;

  /** 指定付款方式, null 表示可讓客戶自行選擇 */
  specifyPaymentType?: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";

  /** 客戶使用的付款方式 */
  usedPaymentType?: "CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH";

  /** 交易資訊 */
  paymentInfo?: string;

  /** 付款資訊 */
  config?: MbrStoreOrderPrePayConfigDto;

  /** 可用訂金支付方式 */
  availablePaymentTypes?: ("CREDIT_CARD" | "LINE_PAY" | "BANK_TRANSFER" | "DEPOSIT_POINT" | "CASH")[];
}

/**
 * 付款資訊
 */
export interface MbrStoreOrderPrePayConfigDto {
  /** 銀行 */
  bank?: string;

  /** 銀行帳號 */
  account?: string;

  /**
   * 訂金金額(舊)
   * @format double
   */
  prePayAmount?: number;

  /** 訂金說明 */
  description?: string;
}

export interface MbrGroupedAvailableConsumerVoucherDto {
  /** 票券 */
  vouchers: MbrConsumerVoucherDto[];

  /** 票券設定 */
  voucherTemplate: MbrVoucherTemplateDto;
}

export interface MbrCheckReservationEligibilityDto {
  eligibility: boolean;
  title: string;
  content: string;
}

export interface MbrConsumerDocTemplateDto {
  /**
   * id of doc
   * @format int32
   */
  id: number;

  /**
   * 品牌id
   * @format int32
   */
  groupId: number;

  /** 文件標題 */
  title: string;

  /** 會員自行簽署與填寫 */
  statusForConsumer: "ON" | "OFF";

  /** 是否需要簽名 */
  signRequired: "ON" | "OFF";

  /**
   * 排序
   * @format int32
   */
  sort: number;

  /** 預覽文件使用之uuid */
  uuid: string;

  /**
   * 上次簽署時間
   * @format date-time
   */
  lastFillAt?: string;
}

export interface MbrConsumerDocTemplateDetailDto {
  /** 文件選項內容 */
  consumerDocTemplateItem: MbrConsumerDocTemplateItemDto[];
  consumerDocTemplate: MbrConsumerDocTemplateDto;
}

/**
 * 文件選項內容
 */
export interface MbrConsumerDocTemplateItemDto {
  /**
   * 排序
   * @format int32
   */
  sort: number;

  /** 內容種類 */
  type: "SINGLE" | "MULTIPLE" | "SHORT_ANSWER" | "DESCRIPTION";

  /** 回答是否必填 */
  required: "ON" | "OFF";

  /** 內容 = DocTemplateItemFilling.questionText */
  content: string;

  /** 選項內容 */
  options: string[];
}

export interface MbrConsumerDocDetailDto {
  /** 文件標題 */
  docTitle: string;
  consumerDoc: MbrConsumerDocDto[];
}

export interface ApiErrorResponse {
  code: string;
  name: string;
  messages: string[];
}

/**
 * API Version
 * @example 20220901
 */
export type ApiVersion = string;

import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, ResponseType } from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || "http://hunger-sit-api.ap-northeast-1.elasticbeanstalk.com",
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === "object" && property !== null
          ? JSON.stringify(property)
          : `${property}`,
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      requestParams.headers.common = { Accept: "*/*" };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Hunger API - 商家前台
 * @version 1.0
 * @baseUrl http://hunger-sit-api.ap-northeast-1.elasticbeanstalk.com
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  mbrStoreApi = {
    /**
     * No description
     *
     * @tags MbrStoreApi
     * @name BindStoreLiff
     * @summary bindStoreLiff 綁定Store上的line liff
     * @request PUT:/api/v3/mbr/stores/byHash/{storeHashId}/lineLiffBind
     * @secure
     */
    bindStoreLiff: (storeHashId: string, data: MbrBindStoreLiffReq, params: RequestParams = {}) =>
      this.request<MbrStoreDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/stores/byHash/${storeHashId}/lineLiffBind`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrStoreApi
     * @name GetGroupStores
     * @summary getGroupStores 取得Group下的Stores
     * @request POST:/api/v3/mbr/group/{groupDomain}/stores
     * @secure
     */
    getGroupStores: (groupDomain: string, params: RequestParams = {}) =>
      this.request<MbrStoreDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/stores`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrStoreApi
     * @name GetStoreOrderAnnouncement
     * @summary getStoreOrderAnnouncement 取得Store的預約公告事項
     * @request GET:/api/v3/mbr/stores/{storeId}
     * @secure
     */
    getStoreOrderAnnouncement: (storeId: number, params: RequestParams = {}) =>
      this.request<MbrStoreOrderAnnouncementDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/stores/${storeId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrStoreApi
     * @name GetStoreByHashId
     * @summary getStoreByHashId 取得Store
     * @request GET:/api/v3/mbr/stores/byHash/{storeHashId}
     * @secure
     */
    getStoreByHashId: (storeHashId: string, params: RequestParams = {}) =>
      this.request<MbrStoreDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/stores/byHash/${storeHashId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrProfileApi = {
    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name UpdateProfile
     * @summary updateProfile 更新用戶基本資訊
     * @request PUT:/api/v3/mbr/profile
     * @secure
     */
    updateProfile: (data: MbrUpdateProfileReq, params: RequestParams = {}) =>
      this.request<MbrProfileDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/profile`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name UpdateUserTwodayLineLiffId
     * @summary updateUserTwodayLineLiffId 更新Twoday綁定的line liff id
     * @request PUT:/api/v3/mbr/profile/twodayLineLiffId
     * @secure
     */
    updateUserTwodayLineLiffId: (data: MbrBindTwodayLineLiffReq, params: RequestParams = {}) =>
      this.request<void, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/profile/twodayLineLiffId`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name LmpUnBind
     * @summary lmpUnBind 解除綁定LMP
     * @request PUT:/api/v3/mbr/profile/lmpUnBind
     * @secure
     */
    lmpUnBind: (data: MbrLmpUnBindReq, params: RequestParams = {}) =>
      this.request<void, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/profile/lmpUnBind`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name LmpBind
     * @summary lmpBind 綁定LMP
     * @request PUT:/api/v3/mbr/profile/lmpBind
     * @secure
     */
    lmpBind: (data: MbrLmpBindReq, params: RequestParams = {}) =>
      this.request<void, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/profile/lmpBind`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name UpdateUserGroupLineId
     * @summary updateUserGroupLineId 更新group綁定的line id
     * @request PUT:/api/v3/mbr/profile/groupLineId
     * @secure
     */
    updateUserGroupLineId: (data: MbrBindGroupLineIdReq, params: RequestParams = {}) =>
      this.request<void, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/profile/groupLineId`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name UpdateAvatarPhoto
     * @summary updateAvatarPhoto 更新頭像照片
     * @request PUT:/api/v3/mbr/profile/avatarPhoto
     * @secure
     */
    updateAvatarPhoto: (data: MbrUpdateAvatarPhotoReq, params: RequestParams = {}) =>
      this.request<MbrProfileDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/profile/avatarPhoto`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name BindGroup
     * @summary bindGroup 綁定Group
     * @request PUT:/api/v3/mbr/bindGroup
     * @secure
     */
    bindGroup: (data: MbrBindGroupReq, params: RequestParams = {}) =>
      this.request<MbrProfileDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/bindGroup`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrProfileApi
     * @name GetMbrUserProfile
     * @summary getMbrUserProfile 取得會員資訊
     * @request GET:/api/v3/mbr/me
     * @secure
     */
    getMbrUserProfile: (query?: { groupDomain?: string }, params: RequestParams = {}) =>
      this.request<MbrProfileDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/me`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrFileApi = {
    /**
     * No description
     *
     * @tags MbrFileApi
     * @name CommitUpload
     * @summary commitUpload 完成上傳檔案
     * @request PUT:/api/v3/mbr/file/commitUpload
     * @secure
     */
    commitUpload: (data: CommitFileUploadReq, params: RequestParams = {}) =>
      this.request<FileDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/file/commitUpload`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrFileApi
     * @name IssueUpload
     * @summary issueUpload 請求上傳檔案
     * @request POST:/api/v3/mbr/file/issueUpload
     * @secure
     */
    issueUpload: (data: IssueFileUploadReq, params: RequestParams = {}) =>
      this.request<IssueFileUploadResult, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/file/issueUpload`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mbrConsumerDocApi = {
    /**
     * No description
     *
     * @tags MbrConsumerDocApi
     * @name FillConsumerDoc
     * @summary fillConsumerDoc 會員填寫文件 完成
     * @request PUT:/api/v3/mbr/consumerDocTemplate/{docTemplateId}/doc/{consumerDocId}
     * @secure
     */
    fillConsumerDoc: (
      docTemplateId: number,
      consumerDocId: number,
      data: MbrFillConsumerDocReq,
      params: RequestParams = {},
    ) =>
      this.request<MbrConsumerDocDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/consumerDocTemplate/${docTemplateId}/doc/${consumerDocId}`,
        method: "PUT",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrConsumerDocApi
     * @name CreateConsumerDoc
     * @summary createConsumerDoc 會員填寫文件 建立
     * @request POST:/api/v3/mbr/consumerDocTemplate/{docTemplateId}/doc
     * @secure
     */
    createConsumerDoc: (docTemplateId: number, params: RequestParams = {}) =>
      this.request<MbrConsumerDocDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/consumerDocTemplate/${docTemplateId}/doc`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrConsumerDocApi
     * @name GetConsumerDocTemplates
     * @summary getConsumerDocTemplates 查詢會員問卷與同意書
     * @request GET:/api/v3/mbr/consumerDocTemplate
     * @secure
     */
    getConsumerDocTemplates: (query?: { groupDomain?: string }, params: RequestParams = {}) =>
      this.request<MbrConsumerDocTemplateDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/consumerDocTemplate`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrConsumerDocApi
     * @name GetConsumerDocTemplate
     * @summary getConsumerDocTemplate 查詢會員問卷與同意書內容
     * @request GET:/api/v3/mbr/consumerDocTemplate/{docTemplateId}
     * @secure
     */
    getConsumerDocTemplate: (docTemplateId: number, params: RequestParams = {}) =>
      this.request<MbrConsumerDocTemplateDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/consumerDocTemplate/${docTemplateId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrConsumerDocApi
     * @name GetConsumerDocs
     * @summary getConsumerDocs 查詢會員填寫過問卷與同意書
     * @request GET:/api/v3/mbr/consumerDoc/{docTemplateId}/docs
     * @secure
     */
    getConsumerDocs: (docTemplateId: number, query?: { groupDomain?: string }, params: RequestParams = {}) =>
      this.request<MbrConsumerDocDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/consumerDoc/${docTemplateId}/docs`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrResourceApi = {
    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name SearchUnSpecifyResourceGroupAvailableSlot
     * @summary searchUnSpecifyResourceGroupAvailableSlot 查詢不指定預約組合可預約時間
     * @request POST:/api/v3/mbr/unSpecifyResourceGroup/{unSpecifyResourceGroupId}/slots
     * @secure
     */
    searchUnSpecifyResourceGroupAvailableSlot: (
      unSpecifyResourceGroupId: number,
      data: MbrSearchResourceAvailableSlotReq,
      params: RequestParams = {},
    ) =>
      this.request<MbrResourceAvailableSlotDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/unSpecifyResourceGroup/${unSpecifyResourceGroupId}/slots`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetStoreResources
     * @summary getStoreResources 取得Store下的Resources
     * @request POST:/api/v3/mbr/store/{storeId}/resources
     * @secure
     */
    getStoreResources: (storeId: number, params: RequestParams = {}) =>
      this.request<MbrResourceDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/store/${storeId}/resources`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name SearchResourceAvailableSlot
     * @summary searchResourceAvailableSlot 查詢Resource可預約時間
     * @request POST:/api/v3/mbr/resource/{resourceId}/slots
     * @secure
     */
    searchResourceAvailableSlot: (
      resourceId: number,
      data: MbrSearchResourceAvailableSlotReq,
      params: RequestParams = {},
    ) =>
      this.request<MbrResourceAvailableSlotDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/resource/${resourceId}/slots`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetUnSpecifyResourceGroupDetail
     * @summary getUnSpecifyResourceGroupDetail 取得不指定設計師組合詳細資訊
     * @request GET:/api/v3/mbr/unSpecifyResourceGroup/{unSpecifyResourceGroupId}
     * @secure
     */
    getUnSpecifyResourceGroupDetail: (unSpecifyResourceGroupId: number, params: RequestParams = {}) =>
      this.request<MbrUnSpecifyResourceGroupDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/unSpecifyResourceGroup/${unSpecifyResourceGroupId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetUnSpecifyResourceGroupPrePayInfo
     * @summary getUnSpecifyResourceGroupPrePayInfo 取得該不指定預約組合是否需要訂金才能建立訂單
     * @request GET:/api/v3/mbr/unSpecifyResourceGroup/{unSpecifyResourceGroupId}/prePayInfo
     * @secure
     */
    getUnSpecifyResourceGroupPrePayInfo: (
      unSpecifyResourceGroupId: number,
      query?: { orderPrice?: number },
      params: RequestParams = {},
    ) =>
      this.request<MbrResourceRequirePrePayInfoDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/unSpecifyResourceGroup/${unSpecifyResourceGroupId}/prePayInfo`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetResourcesForReservation
     * @summary getResourcesForReservation 取得Store下的可預約設計師清單
     * @request GET:/api/v3/mbr/store/{storeId}/resourcesForReservation
     * @secure
     */
    getResourcesForReservation: (storeId: number, params: RequestParams = {}) =>
      this.request<MbrResourcesForReservationDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/store/${storeId}/resourcesForReservation`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetResourceDetail
     * @summary getResourceDetail 取得Resource詳細資訊
     * @request GET:/api/v3/mbr/resource/{resourceId}
     * @secure
     */
    getResourceDetail: (resourceId: number, params: RequestParams = {}) =>
      this.request<MbrResourceDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/resource/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetResourceQuestions
     * @summary getResourceQuestions 取得Resource問題清單
     * @request GET:/api/v3/mbr/resource/{resourceId}/questions
     * @secure
     */
    getResourceQuestions: (resourceId: number, params: RequestParams = {}) =>
      this.request<MbrResourceQuestionDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/resource/${resourceId}/questions`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrResourceApi
     * @name GetResourcePrePayInfo
     * @summary getResourcePrePayInfo 取得該Resource是否需要訂金才能建立訂單
     * @request GET:/api/v3/mbr/resource/{resourceId}/prePayInfo
     * @secure
     */
    getResourcePrePayInfo: (resourceId: number, query?: { orderPrice?: number }, params: RequestParams = {}) =>
      this.request<MbrResourceRequirePrePayInfoDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/resource/${resourceId}/prePayInfo`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrTwodayApi = {
    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name SearchStore
     * @summary searchStore 查詢 Twoday Store
     * @request POST:/api/v3/mbr/td/stores/search
     * @secure
     */
    searchStore: (data: MbrSearchTwodayStoreReq, params: RequestParams = {}) =>
      this.request<PageDtoMbrTwodayStoreDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/stores/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name SearchStoreWithSlot
     * @summary searchStoreWithSlot 查詢 Twoday Store 及可預約組合及時段
     * @request POST:/api/v3/mbr/td/stores/searchWithSlot
     * @secure
     */
    searchStoreWithSlot: (data: MbrSearchTwodayStoreWithSlotReq, params: RequestParams = {}) =>
      this.request<MbrTwodayStoreWithSlotDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/stores/searchWithSlot`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name GetReservationSetWithSlots
     * @summary getReservationSetWithSlots 取得 Twoday 預約組合內容及可預約時段
     * @request POST:/api/v3/mbr/td/reservationSets/{reservationSetId}
     * @secure
     */
    getReservationSetWithSlots: (
      reservationSetId: number,
      data: MbrTwodayGetReservationSetWithSlotDetailReq,
      params: RequestParams = {},
    ) =>
      this.request<MbrTwodayStoreReservationSetWithSlotDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/reservationSets/${reservationSetId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name SearchRequirePrePayOrders
     * @summary searchRequirePrePayOrders 查詢需要支付訂金的訂單
     * @request POST:/api/v3/mbr/td/orders/search/requirePrePay
     * @secure
     */
    searchRequirePrePayOrders: (data: PagingAndSortingRequest, params: RequestParams = {}) =>
      this.request<PageDtoMbrTwodayOrderDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/orders/search/requirePrePay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name SearchIncomingOrders
     * @summary searchIncomingOrders 查詢 Twoday 預約 - 即將到來
     * @request POST:/api/v3/mbr/td/orders/search/imcoming
     * @secure
     */
    searchIncomingOrders: (data: PagingAndSortingRequest, params: RequestParams = {}) =>
      this.request<PageDtoMbrTwodayOrderDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/orders/search/imcoming`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name SearchHistoryOrders
     * @summary searchHistoryOrders 查詢 Twoday 預約 - 歷史紀錄
     * @request POST:/api/v3/mbr/td/orders/search/history
     * @secure
     */
    searchHistoryOrders: (data: PagingAndSortingRequest, params: RequestParams = {}) =>
      this.request<PageDtoMbrTwodayOrderDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/orders/search/history`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name GetStore
     * @summary getStore 取得 Twoday Store
     * @request GET:/api/v3/mbr/td/stores/{storeId}
     * @secure
     */
    getStore: (storeId: number, params: RequestParams = {}) =>
      this.request<MbrTwodayStoreDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/stores/${storeId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrTwodayApi
     * @name GetOrder
     * @summary getOrder 取得 Twoday 預約
     * @request GET:/api/v3/mbr/td/orders/{shortId}
     * @secure
     */
    getOrder: (shortId: string, params: RequestParams = {}) =>
      this.request<MbrTwodayOrderDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/td/orders/${shortId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrOrderApi = {
    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name CreateOrder
     * @summary createOrder 建立訂單
     * @request POST:/api/v3/mbr/orders
     * @secure
     */
    createOrder: (data: MbrCreateOrderReq, params: RequestParams = {}) =>
      this.request<MbrOrderDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name IssueOrderPrePay
     * @summary issueOrderPrePay 開始訂金付款
     * @request POST:/api/v3/mbr/orders/{shortId}/prePay
     * @secure
     */
    issueOrderPrePay: (shortId: string, data: MbrOrderPrePayPaymentReq, params: RequestParams = {}) =>
      this.request<MbrOrderPrePayPaymentRes, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders/${shortId}/prePay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name SearchRequirePrePayOrders1
     * @summary searchRequirePrePayOrders 查詢需要支付訂金的訂單
     * @request POST:/api/v3/mbr/orders/search/requirePrePay
     * @secure
     */
    searchRequirePrePayOrders1: (data: MbrSearchOrderReq, params: RequestParams = {}) =>
      this.request<PageDtoMbrOrderWithPrePayDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders/search/requirePrePay`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name SearchIncomingOrders1
     * @summary searchIncomingOrders 查詢即將到來的訂單
     * @request POST:/api/v3/mbr/orders/search/incoming
     * @secure
     */
    searchIncomingOrders1: (data: MbrSearchOrderReq, params: RequestParams = {}) =>
      this.request<PageDtoMbrOrderDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders/search/incoming`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name SearchHistoryOrders1
     * @summary searchHistoryOrders 查詢歷史訂單
     * @request POST:/api/v3/mbr/orders/search/history
     * @secure
     */
    searchHistoryOrders1: (data: MbrSearchOrderReq, params: RequestParams = {}) =>
      this.request<PageDtoMbrOrderDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders/search/history`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name GetOrderDetailByShortId
     * @summary getOrderDetailByShortId 用ShortId取得訂單資料
     * @request GET:/api/v3/mbr/orders/{shortId}
     * @secure
     */
    getOrderDetailByShortId: (shortId: string, params: RequestParams = {}) =>
      this.request<MbrOrderDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders/${shortId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrOrderApi
     * @name GetOrderPrePayInfoByShortId
     * @summary getOrderPrePayInfoByShortId 用ShortId取得訂單訂金資料
     * @request GET:/api/v3/mbr/orders/{shortId}/prePayInfo
     * @secure
     */
    getOrderPrePayInfoByShortId: (shortId: string, params: RequestParams = {}) =>
      this.request<MbrOrderPrePayDetailDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/orders/${shortId}/prePayInfo`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrVoucherApi = {
    /**
     * No description
     *
     * @tags MbrVoucherApi
     * @name SearchVoucherHistory
     * @summary searchVoucherHistory 查詢Group下的票券紀錄
     * @request POST:/api/v3/mbr/group/{groupDomain}/vouchersHistory/search
     * @secure
     */
    searchVoucherHistory: (groupDomain: string, data: PagingAndSortingRequest, params: RequestParams = {}) =>
      this.request<PageDtoMbrConsumerVoucherHistoryDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/vouchersHistory/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrVoucherApi
     * @name GetVoucherTransferTarget
     * @summary getVoucherTransferTarget 查詢票券轉贈對象
     * @request POST:/api/v3/mbr/group/{groupDomain}/vouchers/transferTarget
     * @secure
     */
    getVoucherTransferTarget: (groupDomain: string, data: MbrGetVoucherTransferTargetReq, params: RequestParams = {}) =>
      this.request<MbrVoucherTransferTargetDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/vouchers/transferTarget`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrVoucherApi
     * @name GetVouchersTemplatesForBonusExchange
     * @summary getVouchersTemplatesForBonusExchange 取得Group下的可用紅利點數兌換的票券
     * @request GET:/api/v3/mbr/group/{groupDomain}/voucherTemplates/bonusExchange
     * @secure
     */
    getVouchersTemplatesForBonusExchange: (groupDomain: string, params: RequestParams = {}) =>
      this.request<MbrVoucherTemplateDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/voucherTemplates/bonusExchange`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrVoucherApi
     * @name ExchangeVoucherByBonus
     * @summary exchangeVoucherByBonus 兌換票券
     * @request POST:/api/v3/mbr/group/{groupDomain}/voucherTemplates/bonusExchange
     * @secure
     */
    exchangeVoucherByBonus: (groupDomain: string, data: MbrExchangeVoucherByBonusReq, params: RequestParams = {}) =>
      this.request<MbrConsumerVoucherHistoryDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/voucherTemplates/bonusExchange`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrVoucherApi
     * @name TransferVoucher
     * @summary transferVoucher 轉贈票券
     * @request POST:/api/v3/mbr/group/{groupDomain}/transferVoucher
     * @secure
     */
    transferVoucher: (groupDomain: string, data: MbrTransferVoucherReq, params: RequestParams = {}) =>
      this.request<MbrConsumerVoucherHistoryDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/transferVoucher`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags MbrVoucherApi
     * @name GetAvailableVouchers
     * @summary getAvailableVouchers 取得Group下的可用票券
     * @request GET:/api/v3/mbr/group/{groupDomain}/vouchers/available
     * @secure
     */
    getAvailableVouchers: (groupDomain: string, params: RequestParams = {}) =>
      this.request<MbrGroupedAvailableConsumerVoucherDto[], { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/vouchers/available`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  mbrDepositPointApi = {
    /**
     * No description
     *
     * @tags MbrDepositPointApi
     * @name SearchDepositPointHistory
     * @summary searchDepositPointHistory 查詢Group下儲值點數紀錄
     * @request POST:/api/v3/mbr/group/{groupDomain}/depositHistory/search
     * @secure
     */
    searchDepositPointHistory: (groupDomain: string, data: PagingAndSortingRequest, params: RequestParams = {}) =>
      this.request<PageDtoMbrConsumerGroupDepositHistoryDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/depositHistory/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mbrBonusPointApi = {
    /**
     * No description
     *
     * @tags MbrBonusPointApi
     * @name SearchBonusPointHistory
     * @summary searchBonusPointHistory 查詢Group下紅利點數紀錄
     * @request POST:/api/v3/mbr/group/{groupDomain}/bonusHistory/search
     * @secure
     */
    searchBonusPointHistory: (groupDomain: string, data: PagingAndSortingRequest, params: RequestParams = {}) =>
      this.request<PageDtoMbrConsumerGroupBonusHistoryDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/bonusHistory/search`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mbrReservationLimitApi = {
    /**
     * No description
     *
     * @tags MbrReservationLimitApi
     * @name CheckReservationEligibility
     * @summary checkReservationEligibility 查詢會員是否符合預約資格
     * @request GET:/api/v3/mbr/group/{groupDomain}/store/{storeId}/checkReservationEligibility
     * @secure
     */
    checkReservationEligibility: (groupDomain: string, storeId: number, params: RequestParams = {}) =>
      this.request<MbrCheckReservationEligibilityDto, { code: string; name: string; messages: string[] }>({
        path: `/api/v3/mbr/group/${groupDomain}/store/${storeId}/checkReservationEligibility`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
