import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
} from 'react';

import {
  Auth,
  ConfirmationResult,
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signOut,
} from 'firebase/auth';

import { NOOP } from '@src/constant';

import { isBrowser } from '@src/utils';

import { loadingEventEmitter } from '@src/event/event';

interface FirebaseContextStore {
  issuePhone: (config: { phoneCountryCode: string; phone: string }) => void;
  verifyCode: (code: string) => Promise<{ idToken: string }>;
  resetRecaptcha: () => void;
  signOutFirebase: () => void;
}

const FirebaseContext = createContext<FirebaseContextStore>({
  issuePhone: NOOP,
  verifyCode: NOOP,
  resetRecaptcha: NOOP,
  signOutFirebase: NOOP,
});

export const FirebaseContextProvider: FC<{ isFirebaseSetup: boolean }> = ({
  children,
  isFirebaseSetup,
}) => {
  const confirmationResult = useRef<null | ConfirmationResult>(null);
  const auth = useRef<Auth | null>(null);
  const recaptchaVerifier = useRef<RecaptchaVerifier | null>(null);
  const recaptchaWidgetId = useRef<number>(0);

  useEffect(() => {
    if (!isBrowser()) return;
    if (!isFirebaseSetup) return;

    auth.current = getAuth();

    if (document.getElementById('g-recaptcha')) return;

    const container = document.createElement('div');
    container.id = 'g-recaptcha';
    container.style.visibility = 'hidden';
    container.style.display = 'none';
    document.body.appendChild(container);
    recaptchaVerifier.current = new RecaptchaVerifier(
      container,
      {
        size: 'invisible',
        'expired-callback': () => {
          grecaptcha.reset(recaptchaWidgetId.current);
        },
      },
      auth.current
    );
    recaptchaVerifier.current?.render().then((widgetId) => {
      recaptchaWidgetId.current = widgetId;
    });
  }, [isFirebaseSetup]);

  const issuePhone = useCallback(
    async ({
      phoneCountryCode,
      phone,
    }: {
      phoneCountryCode: string;
      phone: string;
    }) => {
      if (!auth.current) return;
      try {
        loadingEventEmitter.emit(true);
        const fullPhone = `+${phoneCountryCode}${
          phone.length > 9 ? phone.slice(1) : phone
        }`;
        confirmationResult.current = await signInWithPhoneNumber(
          auth.current,
          fullPhone,
          recaptchaVerifier.current!
        );
      } catch (e: any) {
        grecaptcha.reset(recaptchaWidgetId.current);
        throw e;
      } finally {
        loadingEventEmitter.emit(false);
      }
    },
    []
  );

  const verifyCode = useCallback(async (code: string) => {
    try {
      loadingEventEmitter.emit(true);
      const result = await confirmationResult.current?.confirm(code);
      const idToken = (await result?.user.getIdToken()) ?? '';
      return { idToken };
    } finally {
      loadingEventEmitter.emit(false);
    }
  }, []);

  const resetRecaptcha = useCallback(() => {
    grecaptcha.reset(recaptchaWidgetId.current);
  }, []);

  const signOutFirebase = useCallback(async () => {
    if (!auth.current) return;
    await signOut(auth.current);
  }, []);

  return (
    <FirebaseContext.Provider
      value={{
        issuePhone,
        verifyCode,
        resetRecaptcha,
        signOutFirebase,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebaseContext = () => useContext(FirebaseContext);
