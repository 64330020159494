import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useAsync } from 'react-use';

import { Liff } from '@line/liff/exports';

import { NOOP, pathname } from '@src/constant';

import { isBrowser } from '@src/utils';

interface State {
  /**
   * @description
   * Liff instance;
   * Should use this variable after liffReady;
   * This variable is reactive;
   */
  liff: Liff | null;
  error: Error | null;
  liffReady: boolean;
  isInLiff: boolean;
  /**
   * @description
   * Get liff instance;
   * Should use this function after liffReady;
   */
  getLiff: () => Liff | null;
}

const LiffContext = createContext<State>({
  liff: null,
  error: null,
  liffReady: false,
  isInLiff: false,
  getLiff: NOOP,
});

export const LiffContextProvider: FC = ({ children }) => {
  const [error, setError] = useState<Error | null>(null);

  const liffRef = useRef<Liff | null>(null);

  const { value: liff = null } = useAsync(
    async () =>
      isBrowser()
        ? new Promise<Liff>((resolve) => {
            import('@line/liff').then(({ default: liff }) => {
              resolve(liff);
            });
          })
        : null,
    []
  );

  // Execute liff.init() when the app is initialized
  const { value: liffReady = false } = useAsync(async () => {
    // to avoid `window is not defined` error
    if (!isBrowser()) return false;
    if (!liff) return false;

    liffRef.current = liff;
    await liff.init({ liffId: process.env.LIFF_ID! }).catch(setError);
    if (!liff?.isLoggedIn()) {
      liff?.login({
        redirectUri: `${window.location.origin}${pathname.auth}`,
      });
    }
    return true;
  }, [liff]);

  const isInLiff = useMemo(
    () => !!(liff?.id && liff?.isLoggedIn()) ?? false,
    [liff]
  );

  const getLiff = useCallback(() => liffRef.current, []);

  return (
    <LiffContext.Provider value={{ liff, error, liffReady, isInLiff, getLiff }}>
      {children}
    </LiffContext.Provider>
  );
};

export const useLiffContext = () => useContext(LiffContext);
