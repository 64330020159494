import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAsyncFn } from 'react-use';

import jsCookie from 'js-cookie';
import jwtDecode, { JwtPayload } from 'jwt-decode';

import { useRouter } from 'next/router';

import { NOOP, pathname, TWODAY_TOKEN_KEY } from '@src/constant';

import { ConsumerAPI, PublicAPI } from '@src/swagger';

import {
  AuthenticateHelper,
  isNotSet,
  storage,
  StorageProperties,
} from '@src/utils';

import { useLiffContext } from '@src/context/LiffContext';

import { MbrProfileDto } from '@src/swagger/consumer.api';

interface State {
  user: MbrProfileDto | null;
  reloadUserProfile: (profile?: MbrProfileDto) => Promise<void>;
  signInDirectly: () => void;
  isSignedIn: boolean;
  signIn: (
    token: string,
    option?: {
      beforeSignedIn?: () => any | Promise<any>;
      skipLiff?: boolean;
      skipReload?: boolean;
      notYetSignedIn?: boolean;
      reloadUserRequired?: boolean;
    }
  ) => void;
  signOut: () => void;
}

const ApplicationContext = createContext<State>({
  user: null,
  reloadUserProfile: NOOP,
  signInDirectly: NOOP,
  isSignedIn: false,
  signIn: NOOP,
  signOut: NOOP,
});

export const ApplicationContextProvider: FC = ({ children }) => {
  const [isSignedIn, setIsSignedIn] = useState<boolean>(false);

  const router = useRouter();
  const { liffReady, getLiff } = useLiffContext();

  const authenticateHelper = useMemo(() => {
    if (!liffReady) return null;
    return new AuthenticateHelper({
      validateNoMatterMethodsAllFailedIfHaveDefaultToken: true,
      throwErrorWhileNoUserAfterAuth: true,
      defaultTokenGetter: () =>
        storage.getter(StorageProperties.ACCESS_TOKEN) ??
        jsCookie.get(TWODAY_TOKEN_KEY) ??
        null,
      tokenValidator: () =>
        ConsumerAPI.mbrProfileApi
          .getMbrUserProfile(
            { groupDomain: '' },
            {
              disableAlert: true,
              noRedirectAfterAuthFailure: true,
            }
          )
          .then((response) => response.data),
      methods: [
        {
          forceCheck: true,
          callback: async () => {
            let token = null;

            const liff = getLiff();
            try {
              if (liff?.id && liff?.isLoggedIn()) {
                const profile = await liff.getProfile();
                const idToken = await liff.getIDToken();
                if (profile && idToken) {
                  const { data } = await PublicAPI.mbrAuthApi.lineLiffAuth(
                    {
                      groupDomain: '',
                      idToken,
                      lineId: profile.userId,
                      twoday: true,
                    },
                    {
                      bypassErrorNames: [
                        'LINE_LOGIN_VERIFY_ERROR',
                        'NOT_BOUND_TO_LINE',
                      ],
                      noRedirectAfterAuthFailure: true,
                    }
                  );
                  token = data.token;
                }
              }
            } catch (e) {
              return null;
            }

            return token;
          },
        },
      ],
      hooks: {
        onAuthSuccess: async (token, user) => {
          console.log('Auth success:');
          signIn(token, {
            notYetSignedIn: user.signUpRequired,
          });
          if (user.signUpRequired) {
            await router.replace(pathname.auth);
          }
        },
        onAuthFailure: (error) => {
          console.info('Auth failure:', error);
          if (![pathname.startAuth, pathname.auth].includes(router.pathname))
            router.replace(pathname.auth);
        },
      },
    });
  }, [liffReady]);

  const [{ value: user = null }, loadUserProfile] = useAsyncFn(
    async (profile?: MbrProfileDto) => {
      if (profile) return profile;

      if (!authenticateHelper) return null;

      await authenticateHelper.fire();
      return authenticateHelper.getUser();
    },
    [authenticateHelper]
  );

  useEffect(() => {
    loadUserProfile();
  }, [loadUserProfile]);

  useEffect(() => {
    if (isNotSet(user) || !isSignedIn) return;
    if (
      [pathname.startAuth, pathname.auth, pathname.loading].includes(
        router.pathname
      )
    )
      router.replace(pathname.mapping);
  }, [isSignedIn, user]);

  const signOut = useCallback(async () => {
    await ConsumerAPI.mbrProfileApi.updateUserTwodayLineLiffId(
      {
        // @ts-ignore
        lineId: null,
        // @ts-ignore
        lineUserName: null,
        // @ts-ignore
        lineProfilePicture: null,
      },
      {
        bypassErrorNames: ['LINE_LOGIN_VERIFY_ERROR', 'NOT_BOUND_TO_LINE'],
        noRedirectAfterAuthFailure: true,
      }
    );
    const liff = getLiff();
    await liff?.logout();
    storage.remove(StorageProperties.ACCESS_TOKEN);
    jsCookie.remove(TWODAY_TOKEN_KEY);
    await router.replace(pathname.auth);
    router.reload();
  }, []);

  // store token
  const storeTokenUp = useCallback((token: string) => {
    if (!token) return;
    storage.accessToken = token;
    const expires = (jwtDecode<JwtPayload>(token).exp ?? 0) * 1000;
    jsCookie.set(TWODAY_TOKEN_KEY, token, {
      secure: process.env.NODE_ENV === 'production',
      expires,
    });
  }, []);

  const signInDirectly = useCallback(() => {
    setIsSignedIn(true);
  }, []);

  const signIn = useCallback<State['signIn']>(
    async (
      token,
      {
        beforeSignedIn = NOOP,
        skipLiff = false,
        notYetSignedIn,
        reloadUserRequired,
      } = {}
    ) => {
      try {
        storeTokenUp(token);

        if (reloadUserRequired) {
          const { data } = await ConsumerAPI.mbrProfileApi.getMbrUserProfile();
          await loadUserProfile(data);
        }

        const expires = (jwtDecode<JwtPayload>(token).exp ?? 0) * 1000;
        jsCookie.set(TWODAY_TOKEN_KEY, token, {
          secure: process.env.NODE_ENV === 'production',
          expires,
        });

        const liff = getLiff();

        if (liff && !skipLiff) {
          const profile = await liff.getProfile();
          await ConsumerAPI.mbrProfileApi.updateUserTwodayLineLiffId(
            {
              lineId: profile.userId,
              lineProfilePicture: profile.pictureUrl,
              lineUserName: profile.displayName,
            },
            {
              bypassErrorNames: ['LINE_LOGIN_VERIFY_ERROR'],
              noRedirectAfterAuthFailure: true,
            }
          );
        }
        await beforeSignedIn();
        if (!notYetSignedIn) setIsSignedIn(true);
      } catch (e) {
        console.warn(e);
      }
    },
    []
  );

  const reloadUserProfile = useCallback(
    async (profile?: MbrProfileDto) => {
      let _profile = profile;
      if (!profile) {
        const { data } = await ConsumerAPI.mbrProfileApi.getMbrUserProfile({
          groupDomain: '',
        });
        _profile = data;
      }
      await loadUserProfile(_profile);
    },
    [loadUserProfile]
  );

  return (
    <ApplicationContext.Provider
      value={{
        user,
        reloadUserProfile,
        signInDirectly,
        isSignedIn,
        signIn,
        signOut,
      }}
    >
      {children}
    </ApplicationContext.Provider>
  );
};

export const useApplicationContext = () => useContext(ApplicationContext);
