import { NextApiRequest, NextApiResponse } from 'next';

import { APIMethod } from '@src/constant';

export const staticCities: Array<Option> = [
  { key: 'TPE', label: '臺北市', active: true },
  { key: 'TPH', label: '新北市', active: true },
  { key: 'TXG', label: '臺中市', active: true },
  { key: 'TNN', label: '臺南市', active: true },
  { key: 'KHH', label: '高雄市', active: true },
  { key: 'TYC', label: '桃園市', active: true },
  { key: 'KLU', label: '基隆市', active: true },
  { key: 'HSH', label: '新竹縣', active: true },
  { key: 'HSC', label: '新竹市', active: true },
  { key: 'MAL', label: '苗栗縣', active: true },
  { key: 'CWH', label: '彰化縣', active: true },
  { key: 'NTO', label: '南投縣', active: true },
  { key: 'YLH', label: '雲林縣', active: true },
  { key: 'CHY', label: '嘉義縣', active: true },
  { key: 'CYI', label: '嘉義市', active: true },
  { key: 'IUH', label: '屏東縣', active: true },
  { key: 'ILN', label: '宜蘭縣', active: true },
  { key: 'HWA', label: '花蓮縣', active: true },
  { key: 'TTT', label: '臺東縣', active: true },
  { key: 'PEH', label: '澎湖縣', active: true },
  { key: 'KMN', label: '金門縣', active: true },
  { key: 'LNN', label: '連江縣', active: true },
];

// noinspection JSUnusedGlobalSymbols
export default (
  req: NextApiRequest,
  res: NextApiResponse<{ payload: Array<Option> }>
) => {
  if (req.method !== APIMethod.GET) return res.status(404);

  return res.status(200).json({
    payload: staticCities,
  });
};
